export const idCardValidates = (cardnum: any) => {
	if (cardnum.length === 18) {
		if (!(/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/.test(cardnum))) {
			return false;
		}
		const idCardWi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 将前17位加权因子保存在数组里
		const idCardY = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]; // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
		let idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
		for (let i = 0; i < 17; i++) {
			idCardWiSum += cardnum.substring(i, i + 1) * idCardWi[i];
		}
		const idCardMod = idCardWiSum % 11; // 计算出校验码所在数组的位置
		const idCardLast = cardnum.substring(17); // 得到最后一位身份证号码
		// 如果等于2，则说明校验码是10，身份证号码最后一位应该是X
		if (idCardMod === 2) {
			if (idCardLast === 'X' || idCardLast === 'x') {
				// console.log("恭喜通过验证啦！");
				return true;
			}
			return false;
		}
		// 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
		if (idCardLast === idCardY[idCardMod]) {
			// console.log("恭喜通过验证啦！");
			return true;
		}
		return false;
	} if (cardnum.length === 15) {
		if (!(/(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/.test(cardnum))) {
			return false;
		}
	} else {
		return false;
	}
	return false;
};
// 验证手机号

export const phoneValidates = (number: any) => {
	const str = /(?:^1[3456789]|^9[28])\d{9}$/;
	return str.test(number);
};

// 验证码
export const codeValidates = function (number: any) {
	const str = /\d{4}/;
	return str.test(number);
};
// 密码验证
export const passwordValidates = function (password: any) {
	const str = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
	return str.test(password);
};

export interface ICache {

	setItem(key: string, value: any): void;

	getItem(key: string): any;

}

export class StorageCache implements ICache {
	static setItem(key: string, value: any) {
		if (!!key && (!!value || value === 0)) {
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			localStorage.setItem(`foollove_${key}`, value);
		}
	}

	static getItem(key: string) {
		let obj:any = localStorage.getItem(`foollove_${key}`);
		if (obj instanceof Object) {
			obj = JSON.parse(obj);
		}
		return obj;
	}

	static removeItem(key:string) {
		localStorage.removeItem(`foollove_${key}`)
	}

	getItem(key: string): any {
	}

	setItem(key: string, value: any): void {
	}
}
