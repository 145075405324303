import Pinyin from '@libs/pinyinUtils';

const cityData: any = require('@libs/city');

const toPinyin = function (str:string) {
	return Pinyin.getSpell(str, (charactor: any, spell: any) => spell[1])[0].toUpperCase();
};

delete cityData.province_list['900000'];
const provinceList: any = { '1': '不限', ...(cityData.province_list) }; // 省
const allCity: any = cityData.city_list
const countyList = cityData.county_list;
const cityList1:any = {};
const cityList2:any = {};
const domestic:any = { '': { '1': '不限' } }; // 国内城市
const arr = ['110000', '120000', '310000', '500000', '710000' ,'810000', '820000'];
const overseas:any = {}; // 国外城市
let sortOverseas: any = {};

for (const city in allCity) {
	if (city < '900000') {
		cityList1[city] = allCity[city];
	} else {
		cityList2[city] = allCity[city];
	}
}

Object.keys(provinceList).map((item: string) => {
	const provinceId = item.slice(0, 2);
	const obj1:any = {};
	obj1['1'] = '不限';
	Object.keys(cityList1).map((city: string) => {
		const cityId = city.slice(0, 2);
		if (provinceId == cityId) {
			obj1[city] = cityList1[city];
		}
	});
	if (arr.indexOf(item) == -1) {
		domestic[item] = obj1;
	} else {
		const obj2:any = {};
		Object.keys(countyList).map((county: string) => {
			const countyId = county.slice(0, 2);
			if (provinceId == countyId) {
				obj2[county] = countyList[county];
			}
		});
		domestic[item] = obj2;
	}
	if(domestic[item]) {
		if(domestic[item] && domestic[item][""] == "不限") {
			domestic[item]["1"] = "不限"
			delete domestic[item][""]
			console.log(domestic[item])
		}
	}
});

Object.keys(cityList2).map((item:string) => {
	const key = toPinyin(cityList2[item].slice(0, 1));
	if (!overseas.hasOwnProperty(key)) {
		overseas[key] = [{
			name: cityList2[item],
			code: item
		}];
	} else {
		overseas[key].push({
			name: cityList2[item],
			code: item
		});
	}
});

Object.keys(overseas).sort().map((item: string) => {
	sortOverseas[item] = overseas[item];
});

sortOverseas = { '#': [{ name: '不限', code: '' }], ...sortOverseas };
export const cityListData: any = {
	'': {
		name: '不限',
		province: { '': '不限' },
		city: { '': { '': '不限' } }
	},
	中国: {
		name: '中国',
		province: provinceList,
		city: domestic
	},
	海外: {
		name: '海外',
		province: sortOverseas,
		tag: Object.keys(sortOverseas)
	}
};
