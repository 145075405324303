import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '@store';
import { Repository } from '@store/models/repositories/types';
import * as RepositoriesActions from '@store/models/repositories/actions';
import RepositoryItem from '@components/RepositoryItem/RepositoryItem';

interface StateProps {
    repositories: Repository[]
}
interface DispatchProps {
    loadRequest(): void
}
type Props = StateProps & DispatchProps
class RepositoryList extends Component<Props> {
	componentDidMount() {
		const { loadRequest } = this.props;
		loadRequest();
	}

	render() {
		const { repositories } = this.props;
		return (
			<div className="repository">
				<ul>
					{repositories.map((repository) => (
						<RepositoryItem key={repository.id} repository={repository} />
					))}
				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	repositories: state.repositories.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(RepositoriesActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RepositoryList);
