import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import routes from '@router/index';
import './App.scss';

export default class App extends Component {
	componentDidMount() {
		document.title = '脱单实验室';
	}

	render() {
		return (
			<div className="App">
				{ renderRoutes(routes[0].routes) }
			</div>
		);
	}
}
