/**
 * Action types
 */

/**
 * 微信配置
 */
export enum WeChatConfigTypes {
	LOAD_WECHATCONFIG_REQUEST = '@wechat/LOAD_WECHATCONFIG_REQUEST',
	LOAD_WECHATCONFIG_SUCCESS = '@wechat/LOAD_WECHATCONFIG_SUCCESS',
	LOAD_WECHATCONFIG_FAILURE = '@wechat/LOAD_WECHATCONFIG_FAILURE'
}

/**
 * Data types
 */
/**
 * 微信配置
 */
export interface WeChatConfig {
	appId: string;
	debug: boolean;
	jsApiList: any;
	nonceStr: string;
	signature: string;
	timestamp: string;
}
/**
 * State types
 */
/**
 * 微信配置
 */
export interface WeChatConfigState {
    readonly data: WeChatConfig;
    readonly loading: boolean;
    readonly error: boolean;
}

/**
 * Action types
 */

/**
 * 微信支付
 */
export enum WeChatSetPaymentTypes {
	LOAD_WECHATPAYMENT_REQUEST = '@wechat/LOAD_WECHATPAYMENT_REQUEST',
	LOAD_WECHATPAYMENT_SUCCESS = '@wechat/LOAD_WECHATPAYMENT_SUCCESS',
	LOAD_WECHATPAYMENT_FAILURE = '@wechat/LOAD_WECHATPAYMENT_FAILURE'
}
/**
 * Data types
 */

/**
 * 微信支付
 */
export interface WeChatSetPayment {
	timeStamp: number; // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
	nonceStr: string; // 支付签名随机串，不长于 32 位
	package: string; // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
	signType: string; // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
	paySign: string; // 支付签名
}
/**
 * State types
 */

/**
 * 微信支付
 */
export interface WeChatSetPaymentState {
	readonly data: WeChatSetPayment;
	readonly loading: boolean;
	readonly error: boolean;
}

/**
 * 微信支付状态
 */
export enum WeChatPaymentStatusTypes {
	LOAD_WECHATPAYMENTSTATUS_REQUEST = '@wechat/LOAD_WECHATPAYMENTSTATUS_REQUEST',
	LOAD_WECHATPAYMENTSTATUS_SUCCESS = '@wechat/LOAD_WECHATPAYMENTSTATUS_SUCCESS',
	LOAD_WECHATPAYMENTSTATUS_FAILURE = '@wechat/LOAD_WECHATPAYMENTSTATUS_FAILURE'
}
/**
 * Data types
 */

/**
 * 微信支付
 */
export interface WeChatPaymentStatus {
	status: string
}
/**
 * State types
 */

/**
 * 微信支付
 */
export interface WeChatPaymentStatusState {
	readonly data: WeChatPaymentStatus;
	readonly loading: boolean;
	readonly error: boolean;
}
