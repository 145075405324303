import { put } from 'redux-saga/effects';
// import api from "../../../service/api";
import jsonrpc from '@libs/jsonrpc';
import { loadFailure, loadSuccess } from './actions';

export function* load() {
	try {
		const response = yield jsonrpc.blind.h5.getFindURL();
		yield put(loadSuccess(response.data));
	} catch (e) {
		yield put(loadFailure());
	}
}
