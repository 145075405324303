import { action } from 'typesafe-actions';
import {
	WeChatConfigTypes,
	WeChatConfig,
	WeChatSetPaymentTypes,
	WeChatSetPayment,
	WeChatPaymentStatusTypes,
	WeChatPaymentStatus
} from './types';

/**
 * 微信配置
 */
export const loadWeChatRequest = () => action(WeChatConfigTypes.LOAD_WECHATCONFIG_REQUEST);
export const loadWeChatSuccess = (data: WeChatConfig) => action(WeChatConfigTypes.LOAD_WECHATCONFIG_SUCCESS, { data });
export const loadWeChatFailure = () => action(WeChatConfigTypes.LOAD_WECHATCONFIG_FAILURE);

/**
 * 微信支付
 */
export const loadWeChatPaymentRequest = (
	p:{
		'customer_id': string,
		'fee_type': string
	}
) => action(WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_REQUEST, p);
export const loadWeChatPaymentSuccess = (data: WeChatSetPayment) => action(WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_SUCCESS, { data });
export const loadWeChatPaymentFailure = () => action(WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_FAILURE);

/**
 * 获取支付状态
 */
export const loadWeChatPaymentStatusRequest = (
	p: string
) => action(WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_REQUEST, p);
export const loadWeChatPaymentStatusSuccess = (data: WeChatPaymentStatus) => action(
	WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_SUCCESS, { data }
);
export const loadWeChatPaymentStatusFailure = () => action(WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_FAILURE);
