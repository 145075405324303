import React from 'react';
import {
	BottomNavigation,
	BottomNavigationAction,
	Container,
	Box
} from '@material-ui/core';

import * as querystring from 'querystring';
import { withRouter, RouteComponentProps } from 'react-router';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import Home from '../Home/Home';
import Mine from '../Mine/Mine';
import './_Layout.scss';

interface State {
	navigationName: string;
	wxCode: string;
}
type Props = RouteComponentProps;

class Layout extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			navigationName: '/home',
			wxCode: ''
		};
	}

	componentDidMount() {
		const { location, location: { search } } = this.props;
		let { pathname } = location;
		if (pathname === '/') {
			pathname = '/home';
		}
		this.setState(() => {
			let title = '脱单实验室';
			if (pathname === '/mine') {
				title = '我的';
			}
			document.title = title;
			return {
				navigationName: pathname
			};
		});
		const queryArr:string[] = search.split('?');
		if (queryArr.length === 2) {
			const obj:any = querystring.parse(queryArr[1]);
			const { code } = obj;
			if (code) {
				this.setState({
					wxCode: code
				});
			}
		}
	}

	// 切换tabbar
	changeTab(value: string) {
		const { history } = this.props;
		let title = '脱单实验室';
		if (value === '/mine') {
			title = '我的';
		}
		document.title = title;
		this.setState({
			navigationName: value
		}, () => {
			if (value === '/home') {
				history.push('/home');
			} else {
				history.push('/mine');
			}
		});
	}

	render() {
		const {
			navigationName,
			wxCode
		} = this.state;

		return (
			<Container className="layout" disableGutters>
				<Box>
					{() => (navigationName === '/home' ? <Home /> : navigationName === '/mine' && <Mine code={wxCode} />)}
				</Box>
				<BottomNavigation
					value={navigationName}
					onChange={(event, newValue) => this.changeTab(newValue)}
					showLabels
				>
					<BottomNavigationAction value="/home" label="首页" icon={<HomeIcon />} />
					<BottomNavigationAction value="/mine" label="我的" icon={<PersonIcon />} />
				</BottomNavigation>
			</Container>
		);
	}
}

export default withRouter(Layout);
