import React from 'react';
import './_Member.scss';
import { Box, Button, Container } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import { getCurrentUserInfo, getUserInfoByCode, getMemberSetting, payMember, getPayStatusByTradeNo, getWechatConfig } from '@service/api';
import querystring from "querystring";
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';

type Props = RouteComponentProps;
interface State{
	memberList: any[];
	currentType: number;
	headimgurl: string;
	nickname: string;
	memberType: string;
}
class Member extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			currentType: 2,
			headimgurl: "",
			nickname: "",
			memberType: "member_half",
			memberList: [
				{
					type: "member_month",
					price: 199,
					base: 199
				},
				{
					type: "member_season",
					price: 499,
					base: 166
				},
				{
					type: "member_half",
					price: 599,
					base: 99
				}
			]
		};
	}
	
	async componentDidUpdate() {
		await this.setWechatConfig();
	}

	async setWechatConfig() {
		const response = await getWechatConfig();
		if (response.appId) {
			window.wx.config(response);
			window.wx.error((res:any) => {
				console.log(res);
			});
		}
	}
	async componentDidMount() {
		document.title = 'VIP特权';
		let { location: { search } } = this.props;
		search = search.split("?")[1];
		let searchObj: any = querystring.parse(search);
		if(searchObj && searchObj.code) {
			await getUserInfoByCode(searchObj.code);
		}
		await this.setWechatConfig();
		let { setting } = await getMemberSetting();
		Object.keys(setting).forEach((key:any)=> {
			setting[key].type = key; 
		})
		this.setState({
			"memberList": Object.values(setting).sort((pre:any,cur:any)=> {
				return pre.price - cur.price  
			} )
		})
		let userInfo : any = await getCurrentUserInfo();
		this.setState({
			"headimgurl": userInfo.weixinmp.headimgurl,
			"nickname": userInfo.weixinmp.nickname,
		})
	}

	changeMember(index: number, type: string): void {
		this.setState({
			memberType: type,
			currentType: index
		});
	}
	async payment() {
		let payment:any = null
		try {
			payment = await payMember(this.state.memberType);
		} catch (error:any) {
			if(error && error.msg) {
				Toast.default({
					title: error.msg
				});
			}
		}
		if(!payment) {
			return;
		}
		let timer:any = null;
		window.wx.ready(() => {
			window.wx.chooseWXPay(Object.assign({},{
				timestamp: payment.timeStamp,
				nonceStr: payment.nonceStr,
				package: payment.package,
				signType: payment.signType,
				paySign: payment.paySign,
			},{
				success: ()=> {
					Toast.show({
						title: '支付中，请稍等……'
					});
					timer = setInterval(async () => {
						let result = await getPayStatusByTradeNo(payment.out_trade_no);
						if (result.status === '支付成功') {
							clearInterval(timer);
							await getCurrentUserInfo();
							Toast.hide();
							Toast.default({
								title: '支付成功',
								callback: ()=> {
									window.location.href = "/";
								}
							});
						}
					}, 1000)
				},
				fail: ()=> {
					Toast.hide({
						callback: () => {
							Toast.default({
								title: '微信支付失败'
							});
						}
					});
				}
			}))
		})
	}
	render() {
		const { memberList, currentType, headimgurl, nickname } = this.state;
		const tempArr = ["","季","半年"];
		return (
			<Container className="Member" disableGutters>
				<div className="top-card-wrap">
					<div className="user-info">
						<img src={headimgurl || require('@images/user-img.png')} alt="" />
						<div>{nickname || "nickname"}</div>
					</div>
					<div className="bg">
						<img src={require('@images/z-img.png')} alt="" />
					</div>
					<img className="card" src={require('@images/card.png')} alt="" />
					<img className="bottom" src={require('@images/member/header.png')} alt="" />
				</div>
				<div className="page-title">
					<div className="page-title-wrap">
						<img src={require('@images/tag.png')} alt="" />
						<div>会员权益</div>
						<img src={require('@images/tag.png')} alt="" />
					</div>
				</div>
				<Container className="member-detail">
					<div className="member-item">
						<div className="member-item-title">查看所有嘉宾</div>
						<p className="member-item-info">会员可以查看全站所有嘉宾资料，普通用户仅可查看9位</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">查看隐私微信</div>
						<p className="member-item-info">每个月可以免费查看5次嘉宾的隐私和微信，原价49元/次</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">会员专项折扣</div>
						<p className="member-item-info">购买高端精准匹配服务，专享七七折优惠，购买后请添加红娘微信</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">免费上墙脱单</div>
						<p className="member-item-info">每年可以在公众号进行一次免费推文，曝光给数万人，助你快速脱单，购买后请添加红娘微信</p>
					</div>
					<div className="member-item">
						<div className="member-item-title">会员权益即时到账，购买后不予退款</div>
					</div>
				</Container>
				<div className="page-title">
					<div className="page-title-wrap">
						<img src={require('@images/tag.png')} alt="" />
						<div>服务类型</div>
						<img src={require('@images/tag.png')} alt="" />
					</div>
				</div>
				<Container className="member-type-wrap">
					<div className="member-type-box">
						{
							memberList.map((member: any, index:number) => (
								<Box
									key={member.price}
									 className={`member-type ${index == currentType ? 'active ' : ''} ${index == 2 ? 'recommend' : ''}`}
									 onClick={() => this.changeMember(index, member.type)}
								>
									<div className="title">1个月</div>
									<div className="price">
										￥
										<span className="price-num">{member.base}</span>
									</div>
									<div className="desc">
										{ index != 0 ? `${member.price}元/${tempArr[index]}` : ""}
									</div>
								</Box>
							))
						}
					</div>
					{/* <Button className="member-btn" onClick={()=> {this.payment()}}>立即购买</Button> */}
				</Container>
				<div className="footer">
					<Button disableRipple className="home-btn btns">
						<div className="home__box" onClick={()=> {window.location.href = "/"}}>
							<img className="footer-home-img" src={require('@images/member/home.png')} alt="" />
							<div>首页</div>
						</div>
					</Button>
					<Button className="member-btn" onClick={()=> {this.payment()}}>立即购买</Button>
				</div>
			</Container>
		);
	}
}
export default withRouter(Member);
