import React, { Component } from 'react';
import * as querystring from 'querystring';
import { WeChatConfig } from '@store/models/wechat/types';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '@store';
import {
	Button, Container
} from '@material-ui/core';
import { RouteComponentProps , withRouter} from 'react-router';
import './_Questionnaire.scss';

import writeInformation from '@images/write-information.png';
import writeFinished from '@images/write-finished.png';
import { getUserInfoByCode, getCurrentUserInfo } from '@service/api';
interface State {
	title: string;
}


interface StateProps {
	wechatConfig: WeChatConfig;
}


interface DispatchProps {
	loadWeChatRequest(): object;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class Questionnaire extends Component<Props, State> {
	componentDidMount() {
		const { match , loadWeChatRequest } = this.props;
		loadWeChatRequest();
		const info: any = match.params;
		let title  = info.type == "start" ? "脱单实验室" : "填写完成"; 
		this.setDocumenTitle(title);
		const { location: { search } } = this.props;
		const queryArr:string[] = search.split('?');
		if (queryArr.length === 2) {
			const obj:any = querystring.parse(queryArr[1]);
			const { code } = obj;
			if(code) {
				getUserInfoByCode(code).then((res:any)=> {
					if(res && res.openid) {
						window.location.href =`/nine/write/${info.id}/0`;
					}
				})
			}
		}
		this.setState({
			title
		})
	}

	componentDidUpdate() {
		if(this.state.title) {
			this.setDocumenTitle(this.state.title);
		}
		const { wechatConfig } = this.props;
		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res:any) => {
				console.log(res);
			});
			this.setShare();
		}
	}

	setShare(): void {
		window.wx.ready(() => {
			// 分享到朋友
			const url = window.location.origin + window.location.pathname;
			window.wx.updateAppMessageShareData({
				title: '加入会员库，加速脱单！限时免费！',
				desc: '只需填写基本信息，当有合适的人出现时，将免费为你推荐！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/logo.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '加入会员库，加速脱单！限时免费！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/logo.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
		});
	}

	setDocumenTitle(title:string) {
		document.title = title;
	}
	render() {
		const { match } = this.props;
		const info: any = match.params;
		let content:any = "";
		
		switch(info.type) {
			case "start": 
				content = ( <Container className="question_box">
					<div className="question_box_title">加入会员库，加速脱单！限时免费！</div>
					<div className="question_box_content">只需填写基本信息，当有合适的人出现时，将免费为你推荐！</div>
					<img src={writeInformation} alt="" />
					<Button className="question_box_button" onClick={()=> {
						getCurrentUserInfo().then((res:any)=> {
							if(res && res.weixinmp && res.weixinmp.openid) {
								window.location.href =`/nine/write/${info.id}/0`;
							}
						})
					}}>加入会员库</Button>
				</Container> )
				break;
			case "end": 
			content = ( <Container className="question_box">
				<img src={writeFinished} alt="" className="question_box_finished_img"/>
				<div className="question_box_finished_title">填写完成</div>
				<div className="question_box_finished_content">请等待红娘与您联系～</div>
				<Button className="question_box_button" onClick={() => window.location.href = "/"}>好的</Button>
			</Container> )
			break;
		}
		return content;
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Questionnaire));
