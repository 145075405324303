import jsonrpc from '@libs/jsonrpc';

/**
 * 获取微信用户信息
 * @param params
 * 微信 code
 */
export async function getUserInfoByCode(params: string) {
	return jsonrpc.blind.h5.getUserInfoByCode(params);
}

/**
 * 获取微信配置
 * appId: string
 * debug?: boolean
 * jsApiList: any
 * noncestr: string
 * signature: string
 * timestamp: string
 * window.wx.config({
 * debug: res.debug,
 * appId: res.appId,
 * timestamp: res.timestamp,
 * nonceStr: res.noncestr,
 * signature: res.signature,
 * jsApiList: res.jsApiList
 * });
 */
export async function getWechatConfig() {
	const url = window.location.origin + window.location.pathname;
	return jsonrpc.blind.h5.getConfig(url);
}

/**
 * 微信统一下单 参数获取
 * @param params
 * { customer_id, fee_type }
 * window.wx.ready(() => {
 * window.wx.chooseWXPay({
 * timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
 * nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
 * package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
 * signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
 * paySign: res.paySign, // 支付签名
 * success: (res: any) => {
 * console.log(res, '支付成功');
 * // 支付成功后的回调函数
 * }
 * });
 * });
 */
export async function setPayment(params: object) {
	return jsonrpc.blind.h5.payment(params);
}

/**
 * 首页嘉宾接口
 * @param params
 * {start,limit,gender}
 */
export async function getHomeList(params: object) {
	return jsonrpc.blind.h5.getHomeList(params);
}

/**
 * 个人信息支付列表接口
 */
export async function getFeeType() {
	return jsonrpc.blind.h5.getFeeType();
}

/**
 * 红娘支付列表接口
 */
export async function getRedFeeType() {
	return jsonrpc.blind.h5.getRedFeeType();
}

/**
 * 用户详情页
 * @param params
 * 用户id id
 */
export async function getCustomerByID(params: string) {
	return jsonrpc.blind.h5.getCustomerByID(params);
}

/**
 * 获取当前用户信息
 */
export async function getCurrentUserInfo() {
	return jsonrpc.blind.h5.getCurrentUserInfo();
}

/**
 * 获取支付状态
 * @param params
 * 参数 out_trade_no
 */
export async function getPayStatusByTradeNo(params: string) {
	return jsonrpc.blind.h5.getPayStatusByTradeNo(params);
}

/**
 * 购买个人信息支付
 * @param customerId
 * @param feeType
 */
export async function payment(customerId:string, feeType:string): Promise<any> {
	let arr  = window.location.href.split("/");
	let last = arr[arr.length -1];
	return jsonrpc.blind.h5.payment({
		customer_id: customerId,
		fee_type: feeType
	});
}

/**
 * 购买红娘信息支付
 * @param customerId
 * @param feeType
 */
export async function redPayment(customerId:string, feeType:string): Promise<any> {
	return jsonrpc.blind.h5.redPayment({
		customer_id: customerId,
		fee_type: feeType
	});
}

/**
 * 获取当前用户信息
 */
export async function getRedFreeConfig() {
	return jsonrpc.blind.h5.getRedFreeConfig();
}

export async function getCustomerInfo() {
	return jsonrpc.blind.h5.getCustomerInfo();
}

export async function getQuestions() {
	return jsonrpc.blind.h5.getQuestions();
}

export async function getTokenToUpload() {
	return jsonrpc.blind.admin.getTokenToUpload();
}

export async function updateCustomerInfo(parameter:any) {
	return jsonrpc.blind.h5.updateCustomerInfo(parameter);
}

export async function getMemberSetting() {
	return jsonrpc.blind.h5.getMemberSetting();
}

export async function payMember(member_type:string) {
	return jsonrpc.blind.h5.payMember({member_type});
}