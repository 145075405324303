import { action } from 'typesafe-actions';
import {
	WeChatUserInfoTypes, WeChatUserInfo, CurrentUserInfoTypes
} from './types';

export const loadWeChatUserInfoRequest = (code: string) => action(WeChatUserInfoTypes.LOAD_WECHATUSERINFO_REQUEST, code);
export const loadWeChatUserInfoSuccess = (data: WeChatUserInfo) => action(WeChatUserInfoTypes.LOAD_WECHATUSERINFO_SUCCESS, { data });
export const loadWeChatUserInfoFailure = () => action(WeChatUserInfoTypes.LOAD_WECHATUSERINFO_FAILURE);

export const loadCurrentUserInfoRequest = () => action(CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_REQUEST);
export const loadCurrentUserInfoSuccess = (data: WeChatUserInfo) => action(CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_SUCCESS, { data });
export const loadCurrentUserInfoFailure = () => action(CurrentUserInfoTypes.LOAD_CURRENTUSERINFO_FAILURE);
