import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
	Backdrop, CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import './_ToastComponent.scss';

interface Props {
	show?: boolean;
	title?: string;
	duration?: number;
	toastType?: number;
	type?: number;
	callback?: Function;
}

let toast: any;
let loading: any;
export class Toast extends Component<Props> {
	/**
	 * 显示loading
	 * @param options
	 */
	static show(options:{title?: string, callback?: Function}): void {
		if (!options) {
			options = {};
		}
		options = Object.assign(options, {
			type: 1,
			title: options.title || '加载中...',
			show: true
		});
		this.open(options, true);
	}

	/**
	 * 隐藏loading
	 * @param options
	 */
	static hide(options?:{callback?: Function}): void {
		if (!options) {
			options = {};
		}
		options = Object.assign(options, {
			type: 1,
			show: false
		});

		this.closed(options, true);
	}

	/**
	 * 默认弹窗 没有icon
	 * @param options
	 */
	static default(options:{title: string, callback?: Function, duration?: any}): void {
		options = Object.assign(options, {
			type: 0,
			show: true,
			duration: options.duration || 1500,
			toastType: 0
		});
		this.open(options);
	}

	/**
	 * toast 成功
	 * @param options
	 */
	static success(options:{title: string, callback?: Function, duration?: any}): void {
		options = Object.assign(options, {
			type: 0,
			show: true,
			duration: options.duration || 2000,
			toastType: 1
		});
		this.open(options);
	}

	/**
	 * toast失败
	 * @param options
	 */
	static error(options:{title: string, callback?: Function, duration?: any}): void {
		options = Object.assign(options, {
			type: 0,
			show: true,
			duration: options.duration || 2000,
			toastType: 2
		});
		this.open(options);
	}

	/**
	 * toast info
	 * @param options
	 */
	static info(options:{title: string, callback?: Function, duration?: any}): void {
		options = Object.assign(options, {
			type: 0,
			show: true,
			duration: options.duration || 2000,
			toastType: 3
		});
		this.open(options);
	}

	/**
	 * toast 警告
	 * @param options
	 */
	static warning(options:{title: string, callback?: Function, duration?: any}): void {
		options = Object.assign(options, {
			type: 0,
			show: true,
			duration: options.duration || 2000,
			toastType: 4
		});
		this.open(options);
	}

	/**
	 * 打开loading或者toast
	 * @param options
	 */
	static open(options: Props, isLoading?: boolean) {
		const { ...props } = options || {};
		if (options.type === 0 && !toast) {
			toast = document.createElement('div');
		} else if (options.type === 1 && !loading) {
			loading = document.createElement('div');
		}

		document.body.appendChild(toast || loading);
		ReactDOM.render(<Toast {...props} />, toast || loading);

		if (!isLoading) {
			const timer = setTimeout(() => {
				this.closed(options, isLoading);
				clearTimeout(timer);
			}, options.duration || 2000);
		}
	}

	/**
	 * 关闭loading
	 * @param options
	 */
	static closed(options: { callback?: Function }, isLoading?: boolean) {
		if (options.callback) {
			options.callback();
		}
		if (loading && isLoading) {
			document.body.removeChild(loading);
			loading = undefined;
		}
		if (toast && !isLoading) {
			document.body.removeChild(toast);
			toast = undefined;
		}
	}

	constructor(props:Props) {
		super(props);
	}

	render() {
		const {
			title, toastType, type, show
		} = this.props;
		return (
			<Backdrop invisible className="ToastComponent" open={show || false}>
				{
					[
						<div key="shadow" className="shadow">
							{[
								<Alert key="default" icon={false}>{title}</Alert>,
								<Alert
									className="toast"
									key="success"
									icon={<CheckCircleOutlinedIcon fontSize="inherit" />}
									color="success"
								>
									{title}
								</Alert>,
								<Alert
									className="toast"
									key="error"
									icon={<ErrorOutlineOutlinedIcon fontSize="inherit" />}
									color="error"
								>
									{title}
								</Alert>,
								<Alert
									className="toast"
									key="info"
									icon={<InfoOutlinedIcon fontSize="inherit" />}
									color="info"
								>
									{title}
								</Alert>,
								<Alert
									className="toast"
									key="warning"
									icon={<WarningOutlinedIcon fontSize="inherit" />}
									color="warning"
								>
									{title}
								</Alert>
							][toastType || 0]}
						</div>,
						<Alert className="loading" key="loading" icon={<CircularProgress />}>{title}</Alert>
					][type || 0]
				}
			</Backdrop>
		);
	}
}
