const createUUID = function (noDash?:boolean) {
	const date = Date.now() & 0xffff;

	let factors:any = ((date * date) >> 8) & 0xffff;
	factors = (factors * factors).toString(16);
	factors = factors + factors + factors + factors;

	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
		const random = ((parseInt(factors[factors.length - 1], 16) + Math.random() * 16) % 0xF) | 0;

		factors = factors.slice(0, factors.length - 1);
		return ((character === 'x') ? random : ((random & 0x3) | 0x8)).toString(16);
	});

	if (noDash) {
		return uuid.split('-').join('');
	}

	return uuid;
};

export default createUUID;
