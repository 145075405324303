import { Reducer } from 'redux';
import {
	WeChatConfigState,
	WeChatConfigTypes,
	WeChatPaymentStatusState,
	WeChatPaymentStatusTypes,
	WeChatSetPaymentState,
	WeChatSetPaymentTypes
} from './types';

/**
 * 微信配置
 */
const INITIAL_STATE: WeChatConfigState = {
	data: {
		appId: '',
		debug: false,
		jsApiList: [],
		nonceStr: '',
		signature: '',
		timestamp: ''
	},
	error: false,
	loading: false
};

const reducer: Reducer<WeChatConfigState> = (state = INITIAL_STATE, aciton) => {
	switch (aciton.type) {
		case WeChatConfigTypes.LOAD_WECHATCONFIG_REQUEST:
			return {
				...state, loading: true
			};
		case WeChatConfigTypes.LOAD_WECHATCONFIG_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case WeChatConfigTypes.LOAD_WECHATCONFIG_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

/**
 * 微信支付
 */

const INITIAL_PAYMENT_STATE: WeChatSetPaymentState = {
	data: {
		timeStamp: 0, nonceStr: '', package: '', signType: '', paySign: ''
	},
	error: false,
	loading: false
};

const paymentReducer: Reducer<WeChatSetPaymentState> = (state = INITIAL_PAYMENT_STATE, aciton) => {
	switch (aciton.type) {
		case WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_REQUEST:
			return {
				...state, loading: true
			};
		case WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case WeChatSetPaymentTypes.LOAD_WECHATPAYMENT_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

/**
 * 获取支付状态
 */

const INITIAL_PAYMENTSTATUS_STATE: WeChatPaymentStatusState = {
	data: {
		status: ''
	},
	error: false,
	loading: false
};

const paymentStatusReducer: Reducer<WeChatPaymentStatusState> = (state = INITIAL_PAYMENTSTATUS_STATE, aciton) => {
	switch (aciton.type) {
		case WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_REQUEST:
			return {
				...state, loading: true
			};
		case WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case WeChatPaymentStatusTypes.LOAD_WECHATPAYMENTSTATUS_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

export default {
	reducer,
	paymentReducer,
	paymentStatusReducer
};
