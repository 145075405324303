import React, { Component } from 'react';
import './_Mine.scss';
import {
	Container, Box
} from '@material-ui/core';
import { ApplicationState } from '@store';
import { bindActionCreators, Dispatch } from 'redux';
import * as MineActions from '@store/models/mine/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { WeChatUserInfo } from '@store/models/mine/types';
import * as querystring from 'querystring';
import { StorageCache } from '@libs/utils';
import { Skeleton } from '@material-ui/lab';

const bannerSecond: string = require('@images/banner-second.png');
const vipImage:string = require("@images/me/vip.png");
interface mineProps {
	code: string
}
interface StateProps {
	wechatUserInfo: WeChatUserInfo;
}
interface DispatchProps {
	loadWeChatUserInfoRequest(p: string): void;
	loadCurrentUserInfoRequest(): void;
}
type Props = StateProps & DispatchProps & RouteComponentProps & mineProps;

class Mine extends Component<Props> {
	componentDidMount() {
		document.title = '我的';
		const { loadWeChatUserInfoRequest, loadCurrentUserInfoRequest } = this.props;
		const param: any = querystring.parse(window.location.href.split('?')[1]);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			loadWeChatUserInfoRequest(param.code);
		} else {
			loadCurrentUserInfoRequest();
		}
	}

	saveWeChatUserInfo(wechatUserInfo: WeChatUserInfo) {
		StorageCache.setItem('wxUserInfo', wechatUserInfo);
	}

	changeTab() {
		const { history } = this.props;
		history.push(`/home`);
	}

	// 红娘服务页面
	toMatchmakerDetail(): void {
		const { history } = this.props;
		const path = `/matchmaker-detail/c02c1866-5054-4b62-8858-d8ee4842d46d`;
		history.push(path);
	}

	toMemberDetail() : void {
		const { history } = this.props;
		const path = `/user/member`;
		history.push(path);
	}

	render() {
		const { wechatUserInfo } = this.props;

		if (wechatUserInfo.openid) {
			this.saveWeChatUserInfo(wechatUserInfo);
		}
		let { is_member } = wechatUserInfo;
		return (
			<Container className="mine">
				{/* <Container> */}
					<Box className="mine-header">
						{
							wechatUserInfo.headimgurl
								? <img src={wechatUserInfo.headimgurl} alt="" />
								: <Skeleton animation="wave" variant="circle" width={69} height={69} />

						}
						{
							wechatUserInfo.nickname
								? <div>{wechatUserInfo.nickname}</div>
								: <Skeleton animation="wave" variant="text" width={105} height={24} />
						}

					</Box>
					<Box className="selected">
						<div className="s-box first">
							<div className="number">
								{wechatUserInfo.surplus_view_count}
								次
							</div>
							<div className="tips">剩余查看微信次数</div>
						</div>
						<div className="s-box">
							<div className="number">
								{wechatUserInfo.view_count}
								人
							</div>
							<div className="tips">已查看微信次数</div>
						</div>
					</Box>
					{ is_member ? <img className="banner" src={vipImage} alt="banner" onClick={() => this.toMemberDetail()} /> 
						: <img className="banner" src={bannerSecond} alt="banner" onClick={() => this.toMatchmakerDetail()} />
					}
				{/* </Container> */}
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatUserInfo: state.wechatUserInfo.data.openid ? state.wechatUserInfo.data : state.currentUserInfo.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...MineActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mine));
