import { RouteConfig } from 'react-router-config';
import Layout from '@views/Layout/Layout';
import Detail from '@views/Detail/Detail';
import RepositoryList from '@views/RepositoryList/RepositoryList';
import MatchmakerDetail from '@views/MatchmakerDetail/MatchmakerDetail';
import Questionnaire from '@src/views/Questionnaire/Questionnaire';
import WriteInformation from '@views/WriteInformation/index';
import MP_Questionnaire from '@src/views/MP_Contribution/Questionnaire/Questionnaire';
import MP_WriteInformation from '@views/MP_Contribution/WriteInformation/index';
import Com_Questionnaire from '@src/views/Community/Questionnaire/Questionnaire';
import Com_WriteInformation from '@views/Community/WriteInformation/index';
import Nine_Questionnaire from '@src/views/Nine/Questionnaire/Questionnaire';
import Nine_WriteInformation from '@views/Nine/WriteInformation/index';
import coreInfo from '@views/CoreInfo/coreInfo';
import Member from '@views/Member/Member';

export default [{
	path: '/',
	routes: [
		{
			path: '/:name?',
			exact: true,
			component: Layout
		},
		{
			path: '/detail/:id/:name/:third?',
			component: Detail
		},
		{
			path: '/matchmaker-detail/:id',
			component: MatchmakerDetail
		},
		{
			path: '/repository-list',
			component: RepositoryList
		},
		{
			path: '/questionnaire/:type/:id',
			component: Questionnaire
		},
		{
			path: '/write-information/:id/:step',
			component: WriteInformation
		},
		{
			path: '/mp/questionnaire/:type/:id',
			component: MP_Questionnaire
		},
		{
			path: '/mp/write-information/:id/:step',
			component: MP_WriteInformation
		},
		{
			path: '/community/question/:type/:id',
			component: Com_Questionnaire
		},
		{
			path: '/community/write/:id/:step',
			component: Com_WriteInformation
		},
		{
			path: '/core/write/:id',
			component: coreInfo
		},
		{
			path: '/nine/question/:id',
			component: Nine_Questionnaire
		},
		{
			path: '/nine/write/:id/:step',
			component: Nine_WriteInformation
		},
		{
			path: '/user/member',
			component: Member
		}
	]
}] as RouteConfig[];
