import { Reducer } from 'redux';
import {
	FeeTypeState, FeeTypeTypes, RedFeeTypeState, RedFeeTypeTypes, RedFreeConfigState, RedFreeConfigTypes, UserDetailDataState, UserDetailDataTypes
} from './types';

/**
 *用户数据
 */
const INITIAL_STATE: UserDetailDataState = {
	data: {
		id: '',
		operate_id: '',
		name: '',
		avatar: '',
		cover_img: '',
		img_list: [],
		headList: [],
		gender: '',
		birthday: 0,
		living_place: [],
		hometown: [],
		education: '',
		school: '',
		job: '',
		introduce: [],
		friends_impression: [],
		questions_answers: [],
		age: 0,
		height: 0,
		city: '',
		university: '',
		ideal_object: {},
		is_show: false,
		sort: 0,
		type: '',
		is_removed: false,
		create_date: 0,
		update_date: 0,
		private_info: {},
		is_buy: false,
		recommend: []
	},
	error: false,
	loading: false
};

const reducer: Reducer<UserDetailDataState> = (state = INITIAL_STATE, aciton) => {
	switch (aciton.type) {
		case UserDetailDataTypes.LOAD_USER_DETAIL_REQUEST:
			return {
				...state, loading: true
			};
		case UserDetailDataTypes.LOAD_USER_DETAIL_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case UserDetailDataTypes.LOAD_USER_DETAIL_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

/**
 * 个人信息支付列表
 */

const INITIAL_FEETYPE_STATE: FeeTypeState = {
	data: {
		five: { price: 0, count: 0 ,discount: 0, reduce: 0},
		one: { price: 0, count: 0 ,discount: 0, reduce: 0},
		ten: { price: 0, count: 0 ,discount: 0, reduce: 0}
	},
	error: false,
	loading: false
};

const feeTypeReducer: Reducer<FeeTypeState> = (state = INITIAL_FEETYPE_STATE, aciton) => {
	switch (aciton.type) {
		case FeeTypeTypes.LOAD_GET_FEETYPE_REQUEST:
			return {
				...state, loading: true
			};
		case FeeTypeTypes.LOAD_GET_FEETYPE_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case FeeTypeTypes.LOAD_GET_FEETYPE_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

/**
 * 红娘支付列表
 */

const INITIAL_RED_FREETYPE_STATE: RedFeeTypeState = {
	data: {
		red_one: { price: 0, count: 0 },
		red_three: { price: 0, count: 0 },
		red_recommend: { price: 0, count: 0 }
	},
	error: false,
	loading: false
};

const redFeeTypeReducer: Reducer<RedFeeTypeState> = (state = INITIAL_RED_FREETYPE_STATE, aciton) => {
	switch (aciton.type) {
		case RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_REQUEST:
			return {
				...state, loading: true
			};
		case RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

/**
 * 红娘支付列表
 */

const INITIAL_RED_FREECONFIG_STATE: RedFreeConfigState = {
	data: {
		modal_qrcode: '',
		detail_qrcode: ''
	},
	error: false,
	loading: false
};

const redFeeConfigReducer: Reducer<RedFreeConfigState> = (state = INITIAL_RED_FREECONFIG_STATE, aciton) => {
	switch (aciton.type) {
		case RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_REQUEST:
			return {
				...state, loading: true
			};
		case RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};
export default {
	reducer, feeTypeReducer, redFeeTypeReducer, redFeeConfigReducer
};
