import { call, put } from 'redux-saga/effects';
import { getHomeList } from '@service/api';
import { loadHomeDataSuccess, loadHomeDataFailure } from './actions';

export function* loadHomeData(action:any) {
	try {
		const response = Object.assign(action.payload, yield call(getHomeList, action.payload));
		yield put(loadHomeDataSuccess(response));
	} catch (e) {
		yield put(loadHomeDataFailure());
	}
}
