import React, { Component } from 'react';
import './_Detail.scss';
import {
	Backdrop, Box, Button, Container, Dialog, DialogContent, Grid, IconButton, List, ListItem
} from '@material-ui/core';
import joinImg from '@images/join.png';
import joinHeart from '@images/join-heart.png';
import tagLeft from '@images/tag-left.png';
import tagRight from '@images/tag-right.png';

import HomeIcon from '@images/detail-home.png';
import ShareIcon from '@images/share.png';
import { WeChatConfig, WeChatPaymentStatus, WeChatSetPayment } from '@store/models/wechat/types';
import {
	FeeTypeList, RedFeeTypeList, RedFreeConfigList, UserDetailData
} from '@store/models/detail/types';
import { WeChatUserInfo } from '@store/models/mine/types';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '@store';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as UserDetailDataActions from '@store/models/detail/actions';
import * as MineActions from '@store/models/mine/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import * as querystring from 'querystring';
import uuid from '@libs/uuid';
import { formatDate } from '@libs/dateFormatting';
import {
	getCurrentUserInfo, getPayStatusByTradeNo, getUserInfoByCode, payment
} from '@service/api';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';
import CloseIcon from '@material-ui/icons/Close';
import RecommendComponent from '@components/RecommendComponent/RecommendComponent';
import { StorageCache } from '@libs/utils';

const Ficon: string = require('@images/F.png');
const Micon: string = require('@images/M.png');
const lockIcon: string = require('@images/lock.png');
const closeIcon: string = require('@images/close.png');
import matchImage from "@images/matchmaker/header.png";

interface State {
	openPayList: boolean; // 是否打开支付列表
	payListIndex: number; // 支付列表当前选中的索引
	customerId: string;
	iconObj: any;
	chnNumChar: any;
	showShare: boolean;
	showMatchmaker: boolean;
	showWriteInfo: boolean;
	showAd: boolean;
}

interface mineProps {
	code: string
}

interface StateProps {
	wechatConfig: WeChatConfig;
	userDetailData: UserDetailData;
	feeTypeList: FeeTypeList;
	redFeeTypeList: RedFeeTypeList;
	wechatSetPayment: WeChatSetPayment;
	wechatUserInfo: WeChatUserInfo;
	weChatPaymentStatus: WeChatPaymentStatus;
	redFreeConfigList: RedFreeConfigList;
}

interface DispatchProps {
	loadWeChatUserInfoRequest(p: string): void;

	loadWeChatRequest(): void;

	loadUserDetailRequest(p: string): void;

	loadWeChatPaymentRequest(p: { 'customer_id': string, 'fee_type': string }): void;

	loadGetFeeTypeRequest(): void;

	loadWeChatPaymentStatusRequest(p: string): void;

	loadGetRedFeeConfigRequest(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps & mineProps;

class Detail extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			openPayList: false,
			payListIndex: 0,
			customerId: '',
			iconObj: [
				require('@images/1.png'),
				require('@images/2.png'),
				require('@images/3.png'),
				require('@images/4.png'),
				require('@images/5.png'),
				require('@images/6.png'),
				require('@images/7.png'),
				require('@images/8.png'),
				require('@images/9.png')
			],
			chnNumChar: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
			showShare: false,
			showMatchmaker: false,
			showWriteInfo: false,
			showAd: true
		};
		this.toDetail = this.toDetail.bind(this);
	}

	async componentDidMount() {
		const {
			loadWeChatRequest, loadUserDetailRequest, loadGetFeeTypeRequest, loadGetRedFeeConfigRequest, userDetailData
		} = this.props;
		const { match } = this.props;
		const user: any = match.params;
		this.updateDocumentTitle();
		const param: any = querystring.parse(window.location.href.split('?')[1]);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			await getUserInfoByCode(param.code);
		}
		if(Object.prototype.hasOwnProperty.call(param, 'show')) {
			this.showPrivateData(true);
			const { location: { pathname } } = this.props.history;
			this.props.history.push(pathname);
		}
		loadWeChatRequest();
		loadUserDetailRequest(user.id);
		loadGetFeeTypeRequest();
		this.setState({
			customerId: user.id
		});
	}

	componentDidUpdate() {
		const { wechatConfig, userDetailData } = this.props;
		this.updateDocumentTitle();
		if (wechatConfig.appId && userDetailData.id) {
			window.wx.config(wechatConfig);
			window.wx.error((res:any) => {
				console.log(res);
			});
			this.setShare();
		}
	}

	// 个人信息立即购买
	setPayment(feeType: string): Promise<any> {
		const { customerId } = this.state;
		let timer:any = null;
		return payment(customerId, feeType).then((res: any) => {
			if (res.status === '购买成功') {
				Toast.default({
					title: '购买成功'
				});
				setTimeout(() => {
					const { loadUserDetailRequest, userDetailData } = this.props;
					loadUserDetailRequest(userDetailData.id);
					this.setState({
						openPayList: false
					});
				}, 1000);
				return;
			}
			window.wx.ready(() => {
				window.wx.chooseWXPay({
					timestamp: res.timeStamp,
					nonceStr: res.nonceStr,
					package: res.package,
					signType: res.signType,
					paySign: res.paySign,
					success: (result: any) => {
						Toast.show({
							title: '支付中，请稍等……'
						});
						this.showPrivateData(false);
						this.setState({
							openPayList: false
						});

						timer = setInterval(() => {
							getPayStatusByTradeNo(res.out_trade_no).then(async (resultStatus: any) => {
								if (resultStatus.status === '支付成功') {
									const {
										loadUserDetailRequest, loadGetFeeTypeRequest, userDetailData
									} = this.props;
									loadUserDetailRequest(userDetailData.id);
									if (timer) {
										clearInterval(timer);
									}
									loadGetFeeTypeRequest();
									Toast.hide();
								}
							}).catch((err: any) => {
								console.log(err);
								if (timer) {
									clearInterval(timer);
								}
								Toast.hide({
									callback: () => {
										Toast.default({
											title: err.msg
										});
									}
								});
							});
						}, 1000);
					},
					fail: (err: any) => {
						Toast.hide({
							callback: () => {
								Toast.default({
									title: '微信支付失败'
								});
							}
						});
					}
				});
			});
		}).catch((err: any) => {
			Toast.hide({
				callback: () => {
					this.setState({
						openPayList: false
					});
					Toast.default({
						title: err.msg
					});
				}
			});
		});
	}

	writeInfo() {
		const { match } = this.props;
		const user: any = match.params;
		const { userDetailData } = this.props;
		let baseURL = `/core/write/${userDetailData.operate_id || '36049001-0267-45d5-b2c6-3673ec1e7d8d'}`;
		let domain = `${baseURL}?id=${user.id}&name=${encodeURIComponent(user.name)}`;
		if(user.third) {
			domain = `${domain}&third=${user.third}`
		}
		this.props.history.push(domain);
	}
	// 配置分享
	setShare(): void {
		const { userDetailData } = this.props;
		if (!userDetailData.name) {
			return;
		}

		const url = window.location.origin + window.location.pathname;
		const { avatar, name } = userDetailData;
		window.wx.ready(() => {
			// 分享到朋友
			window.wx.updateAppMessageShareData({
				title: `我发现一个不错的人：${name}`,
				desc: '',
				link: url,
				imgUrl: avatar,
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: `我发现一个不错的人：${name}`,
				link: url,
				imgUrl: avatar,
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
		});
	}

	getDate(date: number) {
		return date ? formatDate(new Date(date), 'YYYY-MM-DD') : '';
	}

	paySelected(index:number): void {
		this.setState({
			payListIndex: index
		});
	}

	updateDocumentTitle() {
		const { match } = this.props;
		const user: any = match.params;
		document.title = decodeURIComponent(user.name);
	}

	// 返回首页
	goBack(): void { 
		const { userDetailData } = this.props;
		StorageCache.setItem('selected_user', userDetailData.gender == "女" ? 1 : 0);
		window.location.href = '/';
	}

	// 解锁
	showPrivateData(flag: boolean): void {
		const { openPayList } = this.state;
		console.log(openPayList, flag);
		this.setState({
			openPayList: !openPayList
		});
	}

	// 打开红娘牵线
	async matchmaker() {
		if(this.state.showWriteInfo) {
			await getCurrentUserInfo();
			const { userDetailData, loadUserDetailRequest } = this.props;
			loadUserDetailRequest(userDetailData.id);
		}
		this.setState({
			showMatchmaker: true
		});
	}

	// 关闭牵线
	closeMatchmaker(): void {
		this.setState({
			showMatchmaker: false
		});
	}

	// 判断是否是会员
	async judgeIsMember() {
		const { customerId } = this.state;
		const wechatUserInfo: any = await getCurrentUserInfo();
		if(!wechatUserInfo.customer_info || !wechatUserInfo.customer_info.privacy_information || !wechatUserInfo.customer_info.privacy_information['微信号']) {
			this.setState({
				"showMatchmaker": true,
				showWriteInfo: true
			})
			return;
		}
		const { userDetailData, loadUserDetailRequest } = this.props;
		loadUserDetailRequest(userDetailData.id);
		if (userDetailData && userDetailData.is_buy) {
			Toast.default({
				title: '已购买'
			});
			return;
		}
		const { weixinmp } = wechatUserInfo;
		if (weixinmp.openid && (weixinmp.surplus_view_count >= 1) || userDetailData.gender == '男') {
			try {
				Toast.show({
					title: '支付中，请稍等……'
				});
				const payResult = await payment(customerId, 'one');
				if (payResult.status === '购买成功') {
					setTimeout(() => {
						const { loadUserDetailRequest, userDetailData } = this.props;
						loadUserDetailRequest(userDetailData.id);
						Toast.hide();
					}, 1500);
				} else if (payResult && payResult.msg) {
					Toast.hide();
					Toast.default({
						title: payResult.msg
					});
				}
				return;
			} catch (error) {
				if (error && error.msg) {
					Toast.default({
						title: error.msg
					});
				}
			}
			return;
		}
		this.showPrivateData(true);
	}

	// 关闭分享提示
	closeShare(): void {
		this.setState({
			showShare: false
		});
	}

	openShare():void {
		const { showShare } = this.state;
		this.setState({
			showShare: !showShare
		});
		this.setShare();
	}

	// 红娘服务页面
	toMatchmakerDetail(): void {
		const { history } = this.props;
		const { userDetailData } = this.props;
		const path = `/matchmaker-detail/${userDetailData.id}`;
		history.push(path);
	}

	toDetail(userObj: any) {
		const { history } = this.props;
		const path = `/detail/${userObj.id}/${encodeURIComponent(`${userObj.name}`)}`;
		history.push(path);
		window.location.reload();
	}

	closeAd() {
		this.setState({
			showAd: false
		})
	}

	render() {
		const {
			userDetailData, feeTypeList
		} = this.props;
		const {
			openPayList, payListIndex,
			chnNumChar, iconObj, showShare,
			showMatchmaker,
			showWriteInfo,
			showAd
		} = this.state;
		if (userDetailData.ideal_object && Object.keys(userDetailData.ideal_object).length > 0) {
			if (typeof userDetailData.ideal_object['年收入'] === 'number') {
				userDetailData.ideal_object['年收入'] = `${userDetailData.ideal_object['年收入']}万`;
			}
			if (userDetailData.ideal_object['年龄范围'] instanceof Array && userDetailData.ideal_object['年龄范围'].length > 1) {
				userDetailData.ideal_object['年龄范围'] = userDetailData.ideal_object['年龄范围'].join('-');
			}
		}
		if (userDetailData.questions_answers && userDetailData.questions_answers.length > 0) {
			userDetailData.questions_answers = userDetailData.questions_answers.filter((item:any) => item.Q);
		}
		if (userDetailData.living_place && userDetailData.living_place.length > 0) {
			userDetailData.living_place = userDetailData.living_place.filter((item:any) => item !== '中国');
		}
		if (userDetailData.hometown && userDetailData.hometown.length > 0) {
			userDetailData.hometown = userDetailData.hometown.filter((item:any) => item !== '中国');
		}

		return (
			<Container className="detail" disableGutters>
				<Container className="wrap" disableGutters>
					<div className="top__ad" style={{display: showAd ? '' : 'none'}}>
						<img className="close" src={closeIcon} alt="close" onClick={() => this.closeAd() } />
						<div className="head">
							{
								userDetailData.headList && userDetailData.headList.length ? userDetailData.headList.map((head:any, index:any) => (
									<div className="head__img" style={{ zIndex: 5-index }} key={head.id}>
										<img key={head.id} src={head.avatar + '?imageView2/1/w/54/h/54'} alt="headImg" />
									</div>
								)) : null
							}
							<div className="head__img more">...</div>
						</div>
						<div className="right" onClick={() => this.goBack()}>前往单身广场</div>
					</div>
					<Box className="top">
						<img key={uuid()} className="big-img" src={userDetailData.cover_img + '?imageView2/1/w/710/h/544' } alt="" />
					</Box>
					<Container className="container">
						<Box className="basic-info">
							<div className="tag-box">
								<img className="tag-left" src={tagLeft} alt="" />
								<img className="tag-right" src={tagRight} alt="" />
							</div>
							<div className="info-title">
								<span>基本信息</span>
							</div>
							<div className="name-gender">
								<div className="name">{userDetailData.name}</div>

								<img
									className="gender"
									src={userDetailData.gender === '男' ? Micon : Ficon}
									alt=""
								/>

							</div>
							<div className="info">
								<Grid container spacing={3}>
									<Grid item xs={12} className="info-item">
										出生日期：
										<div className="info-item-value-7">
											{this.getDate(userDetailData.birthday)}
										</div>
									</Grid>
									<Grid item xs={12} className="info-item">
										身高：
										<div className="info-item-value">
											{userDetailData.height}
											cm
										</div>
									</Grid>
									<Grid item xs={12} className="info-item">
										现居地：
										<div className="info-item-value-7">
											{userDetailData.living_place}
										</div>
									</Grid>
									<Grid item xs={12} className="info-item">
										家乡：
										<div className="info-item-value">
											{userDetailData.hometown}
										</div>
									</Grid>
								</Grid>
							</div>
						</Box>
					</Container>
					<Container className="container">
						<Box className="other-info">
							<div className="tag-box">
								<div className="join-box">
									<img className="join" src={joinImg} alt="" />
								</div>
								<img className="tag-left" src={tagLeft} alt="" />
							</div>
							{

								userDetailData && (userDetailData.education || userDetailData.school || userDetailData.job
									|| (userDetailData.img_list && userDetailData.img_list.length >= 1))
								&& (
									<Box className="education">
										<div className="info-title">
											<span>学历工作信息</span>
										</div>
										<div className="info">
											{
												!!userDetailData.education && (
													<div>
														学历：
														{userDetailData.education}
													</div>
												)
											}
											{
												!!userDetailData.school && (
													<div>
														毕业院校：
														{userDetailData.school}
													</div>
												)
											}
											{
												!!userDetailData.job && (
													<div>
														职业：
														{userDetailData.job}
													</div>
												)
											}
										</div>

										{
											userDetailData.img_list
											&& userDetailData.img_list.length >= 1
											&& (

												<img key={uuid()} className="education-img" src={userDetailData.img_list[0]} alt="" />

											)
										}
									</Box>
								)

							}
							{
								userDetailData && ((userDetailData.introduce && userDetailData.introduce.length > 0)
									|| (userDetailData.img_list && userDetailData.img_list.length >= 2))
								&& (
									<Box className="introduce">
										<div className="info-title">
											<span>自我介绍</span>
										</div>
										{
											userDetailData.introduce && userDetailData.introduce.map((item:any, index:number) => (
												<div key={item} className="introduce-info">
													<div className="dot" />
													<p className="info">{item}</p>
												</div>
											))
										}
										{
											userDetailData.img_list
											&& userDetailData.img_list.length >= 2
											&& (

												<img key={uuid()} className="introduce-img" src={userDetailData.img_list[1]} alt="" />

											)
										}
									</Box>
								)
							}
							{
								userDetailData && ((userDetailData.friends_impression && userDetailData.friends_impression.length > 0)
									|| (userDetailData.img_list
									&& userDetailData.img_list.length >= 3))
								&& (
									<Box className="friends">
										<div className="info-title">
											<span>好友印象</span>
										</div>
										<div className="friends-info">
											{
												userDetailData.friends_impression
												&& userDetailData.friends_impression.map((item:any, index:number) => (
													<div key={item} className="friends-item">
														<img
															className="friends-icon"
															src={iconObj[index]}
															alt=""
														/>
														<div className="item-info">
															<div>
																好友
																{chnNumChar[index]}
															</div>
															<p>{item}</p>
														</div>
													</div>
												))
											}
										</div>
										{
											userDetailData.img_list
											&& userDetailData.img_list.length >= 3
											&& (

												<img key={uuid()} className="friends-img" src={userDetailData.img_list[2]} alt="" />

											)
										}
									</Box>
								)
							}
							{
								userDetailData && ((userDetailData.ideal_object && Object.keys(userDetailData.ideal_object).length > 0)
									|| (userDetailData.img_list
									&& userDetailData.img_list.length >= 4))
								&& (
									<Box className="ideal-lover">
										<div className="info-title">
											<span>理想对象</span>
										</div>
										<div className="info">
											{
												userDetailData.ideal_object
												&& Object.keys(userDetailData.ideal_object).map((item) => (
													(
														<div key={item}>
															{`${item}: ${userDetailData.ideal_object[item]}`}
														</div>
													)
												))
											}
										</div>
										{
											userDetailData.img_list
											&& userDetailData.img_list.length >= 4
											&& (

												<img key={uuid()} className="ideal-lover-img" src={userDetailData.img_list[3]} alt="" />

											)
										}
									</Box>
								)
							}
							{
								userDetailData && (userDetailData.questions_answers.length > 0) && userDetailData.questions_answers && (
									<Box className="question">
										<div className="info-title">
											<span>问答环节</span>
										</div>
										<List className="question-list">
											{
												(userDetailData.questions_answers && userDetailData.questions_answers.length > 0)
												&& userDetailData.questions_answers.map((item:any, index:number) => (
													item.Q && (
														<ListItem key={uuid()} className="question-item">
															<div className="question-detail title">
																<div className="question-index">Q</div>
																<p className="question-title">{item.Q}</p>
															</div>
															<div className="question-detail">
																<div className="question-index">A</div>
																<div className="answer">
																	<p>{item.A}</p>
																</div>
															</div>
														</ListItem>
													)
												))
											}
										</List>
									</Box>
								)
							}
							<Box className="privacy">
								<div className="info-title">
									<span>隐私信息</span>
								</div>
								<List className="privacy-list">
									{
										userDetailData && userDetailData.private_info && Object.keys(userDetailData.private_info).map((item) => (
											<ListItem key={item} className="privacy-item">
												<span className="privacy-title">{`${item}:`}</span>
												<span className={
													userDetailData.is_buy ? 'privacy-info' : 'privacy-detail'
												}
												>
													{userDetailData.private_info[item]}
												</span>
											</ListItem>
										))
									}
								</List>
								{!userDetailData.is_buy && (
									<Button className="lock-btn" onClick={() => this.judgeIsMember()}>

										<img src={lockIcon} alt="" />

										点击查看隐私信息
									</Button>
								)}

								<Dialog
									className="paylist-dialog"
									open={openPayList}
									onClose={() => this.showPrivateData(false)}
									aria-labelledby="alert-dialog-slide-title"
									aria-describedby="alert-dialog-slide-description"
								>
									<DialogContent className="dialog-content">
										<Box className="paylist-box">
											{
												feeTypeList && Object.keys(feeTypeList).map((item: string, index: number) => (
													<Box
														key={item}
														className={payListIndex === index ? 'paylist-item active' : 'paylist-item'}
														onClick={() => this.paySelected(index)}
													>
														<div className="paylist-top">
															查看
															{item === 'five' || item === 'ten' ? `${feeTypeList[item].count}位`
																 : '当前'}
															<span className="text">
																{userDetailData.gender === '男' ? '男士' : '妹子'}
																隐私信息和微信
															</span>
														</div>
														<div className="paylist-bottom">
															<div className="paylist-b-left">
																{feeTypeList[item].price}
																<span className="tag">
																	元
																</span>
															</div>
															{
																feeTypeList[item].reduce ?  <div className="paylist-b-reduce">
																{feeTypeList[item].reduce ? `立减${feeTypeList[item].reduce}元` : ""} </div> : ""
															}
															<Button
																className="paylist-pay"
																onClick={() => this.setPayment(item)}
															>
																{feeTypeList[item].discount ? `${feeTypeList[item].discount}折购买` :"立即购买"}
															</Button>
														</div>
													</Box>
												))
											}
										</Box>
									</DialogContent>
								</Dialog>
							</Box>
						</Box>
					</Container>
					<Container className="container last">
						<Box className="other-info">
							<div className="tag-box">
								<div className="join-box">
									<img className="join" src={joinHeart} alt="" />
								</div>
								<div className="tag-box-swiper">
									<img className="tag-left" src={tagLeft} alt="" />
									<img className="tag-right" src={tagRight} alt="" />
								</div>
							</div>
							<div className="info-title">
								<span>为你推荐</span>
							</div>
							<RecommendComponent
								toDetail={this.toDetail}
								swiperData={userDetailData.recommend}
							/>
						</Box>
					</Container>
				</Container>
				<Box className="footer">
					<Grid container spacing={2}>
						<Grid className="footer-left" item xs={2}>
							<Button disableRipple className="home-btn btns" onClick={() => this.goBack()}>
								<img src={HomeIcon} alt="" />
								<div>首页</div>
							</Button>
							{/* <Button disableRipple className="home-btn btns" onClick={() => this.openShare()}>
								<img src={ShareIcon} alt="" />
								<div>分享</div>
							</Button> */}
						</Grid>
						<Grid className="footer-right" item xs={10}>
							<Button
								variant="contained"
								color="secondary"
								className="matchmaker btn"
								onClick={() => this.matchmaker()}
							>
								红娘牵线
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className="btn"
								onClick={() => this.judgeIsMember()}
							>
								立即联系
								{/* <div className="ellipsis-line btn-name">{userDetailData.name}</div> */}
							</Button>
						</Grid>
					</Grid>
				</Box>
				<Backdrop className="share-box" open={showShare} onClick={() => this.closeShare()}>

					<img className="share-img" src={require('@images/share-img.png')} alt="" />

				</Backdrop>
				<Dialog
					className="matchmaker-box-detail"
					onClose={() => this.closeMatchmaker()}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
					open={showMatchmaker}
				>
					{
						showWriteInfo ?
							<DialogContent className="dialog-content">
								<IconButton aria-label="close" className="close-matchmaker" onClick={() => this.closeMatchmaker()}>
									<CloseIcon />
								</IconButton>
								<img src={matchImage} className="match_image" />
								<div className="info">
									脱单实验室鼓励真实、真诚的社交方式，完善个人信息后，可获取嘉宾的联系方式。
								</div>
								<Button
									variant="contained"
									className="matchmaker-btn"
									onClick={() => this.writeInfo()}
								>
									完善信息并联系
								</Button>
								<div className="qrcode-box" onClick={() => this.closeMatchmaker()}>
									<div>暂不联系</div>
								</div>
							</DialogContent>
						:
							<DialogContent className="dialog-content__matchmaker">
								<IconButton aria-label="close" className="close-matchmaker" onClick={() => this.closeMatchmaker()}>
									<CloseIcon />
								</IconButton>
								<img className="bg" src={require('@images/toast-bg.png')} alt="" />
								<div className="content">
									<h1>高端1v1精准匹配服务</h1>
									<p>从上万位优质会员中为你精准匹配，资深红娘全程跟踪服务，全程保护隐私安全！</p>
								</div>
								<Button
									variant="contained"
									className="matchmaker-btn see__matchmaker"
									onClick={() => this.toMatchmakerDetail()}
								>
									查看红娘服务详情
								</Button>
							</DialogContent>
					}
				</Dialog>
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	userDetailData: state.userDetailData.data,
	feeTypeList: state.feeTypeList.data,
	redFeeTypeList: state.redFeeTypeList.data,
	wechatSetPayment: state.wechatSetPayment.data,
	wechatUserInfo: state.currentUserInfo.data,
	redFreeConfigList: state.redFreeConfigList.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions,
	...UserDetailDataActions,
	...MineActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
