import React from 'react';
import 'swiper/swiper.scss';
import './_RecommendComponent.scss';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@material-ui/core';

const Ficon: string = require('@images/F.png');
const Micon: string = require('@images/M.png');

type Props = {
	swiperData: any;
	toDetail: Function;
}
SwiperCore.use([Autoplay]);
export default function RecommendComponent({ swiperData, toDetail }: Props) {
	return (
		<Swiper
			className="RecommendComponent"
			spaceBetween={50}
			slidesPerView={3}
		>
			{
				swiperData.map((item:any) => (
					<SwiperSlide key={item.id}>
						<Box className="user" onClick={() => toDetail(item)}>
							<img
								className="header-img"
								src={item.avatar}
								alt="头像"
							/>
							<div className="name_gender">
								<div className="username ellipsis-line">{item.name}</div>
								<img
									className="gender"
									src={item.gender === '男' ? Micon : Ficon}
									alt=""
								/>
							</div>
							<div className="age_city">
								<div className="age">{`${item.age}岁`}</div>
								<div className="city ellipsis-line">{item.city}</div>
							</div>
							<div className="school ellipsis-line">{item.university}</div>
						</Box>
					</SwiperSlide>
				))
			}
		</Swiper>
	);
}
