import { createStore, Store, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '@store/models/rootReducer';
import rootSaga from '@store/models/rootSaga';

import { WeChatConfigState, WeChatSetPaymentState, WeChatPaymentStatusState } from '@store/models/wechat/types';
import { RepositoriesState } from '@store/models/repositories/types';
import { HomeDataState } from '@store/models/home/types';
import {
	FeeTypeState, UserDetailDataState, RedFeeTypeState, RedFreeConfigState
} from '@store/models/detail/types';
import { CurrentUserInfoState, WeChatUserInfoState } from '@store/models/mine/types';

export interface ApplicationState {
	repositories: RepositoriesState,
	wechatConfig: WeChatConfigState,
	wechatSetPayment: WeChatSetPaymentState
	homeData: HomeDataState,
	userDetailData: UserDetailDataState,
	feeTypeList: FeeTypeState,
	redFeeTypeList: RedFeeTypeState,
	wechatUserInfo: WeChatUserInfoState,
	currentUserInfo: CurrentUserInfoState,
	weChatPaymentStatus: WeChatPaymentStatusState,
	redFreeConfigList: RedFreeConfigState,
}

const sagaMiddleware = createSagaMiddleware();
const store: Store<ApplicationState> = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
export default store;
