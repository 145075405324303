import React, { Component } from 'react';
import { RedFeeTypeList, RedFreeConfigList } from '@store/models/detail/types';
import {
	Button, Container, Grid
} from '@material-ui/core';
import './_MatchmakerDetail.scss';
import { WeChatConfig } from '@store/models/wechat/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { getUserInfoByCode, redPayment } from '@service/api';
import { Toast } from '@components/FeedBack/ToastComponent/ToastComponent';
import jsonrpc from '@libs/jsonrpc';
import { ApplicationState } from '@store';
import { bindActionCreators, Dispatch } from 'redux';
import * as WeChatConfigActions from '@store/models/wechat/actions';
import * as UserDetailDataActions from '@store/models/detail/actions';
import { connect } from 'react-redux';
import querystring from 'querystring';
import { Skeleton } from '@material-ui/lab';

interface State {
	priceType: number;
	customerId: string;
}

interface StateProps {
	wechatConfig: WeChatConfig;
	redFeeTypeList: RedFeeTypeList;
	redFreeConfigList: RedFreeConfigList;
}

interface DispatchProps {
	loadWeChatRequest(): void;

	loadGetRedFeeTypeRequest(): void;

	loadGetRedFeeConfigRequest(): void;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class MatchmakerDetail extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			priceType: 1,
			customerId: ''
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		const {
			loadWeChatRequest,
			loadGetRedFeeTypeRequest,
			loadGetRedFeeConfigRequest
		} = this.props;
		this.setShare();
		const { match } = this.props;
		const user: any = match.params;
		this.updateDocumentTitle();
		const param: any = querystring.parse(window.location.href.split('?')[1]);
		if (Object.prototype.hasOwnProperty.call(param, 'code')) {
			await getUserInfoByCode(param.code);
		}
		loadWeChatRequest();
		loadGetRedFeeTypeRequest();
		loadGetRedFeeConfigRequest();
		this.setState({
			customerId: user.id
		});
	}

	componentDidUpdate() {
		const { wechatConfig } = this.props;
		this.updateDocumentTitle();

		if (wechatConfig.appId) {
			window.wx.config(wechatConfig);
			window.wx.error((res: any) => {
				console.log(res);
			});
		}
	}

	// 红娘立即购买
	setRedPayment(): Promise<any> {
		const { redFeeTypeList } = this.props;
		const {
			priceType,
			customerId
		} = this.state;
		let timer: any = null;
		const feeType: string = Object.keys(redFeeTypeList)[priceType];
		return redPayment(customerId, feeType)
			.then((res: any) => {
				if (res.status === '购买成功') {
					Toast.default({
						title: '购买成功'
					});
					return;
				}
				window.wx.ready(() => {
					window.wx.chooseWXPay({
						timestamp: res.timeStamp,
						nonceStr: res.nonceStr,
						package: res.package,
						signType: res.signType,
						paySign: res.paySign,
						success: (result: any) => {
							Toast.show({
								title: '支付中，请稍等……'
							});

							timer = setInterval(() => {
								jsonrpc.blind.h5.getPayStatusByTradeNo(res.out_trade_no)
									.then((resultStatus: any) => {
										if (resultStatus.status === '支付成功') {
											if (timer) {
												clearInterval(timer);
											}
											Toast.hide({
												callback: () => {
													Toast.default({
														title: '购买成功'
													});
												}
											});
										}
									})
									.catch((err: any) => {
										if (timer) {
											clearInterval(timer);
										}
										Toast.hide({
											callback: () => {
												Toast.default({
													title: err.msg
												});
											}
										});
									});
							}, 1000);
						},
						fail: (err: any) => {
							Toast.hide({
								callback: () => {
									Toast.default({
										title: '微信支付失败'
									});
								}
							});
						}
					});
				});
			})
			.catch((err: any) => {
				Toast.hide({
					callback: () => {
						Toast.default({
							title: err.msg
						});
					}
				});
			});
	}

	updateDocumentTitle() {
		document.title = '红娘牵线服务';
	}

	setShare(): void {
		const url = window.location.origin + window.location.pathname;
		window.wx.ready(() => {
			window.wx.updateAppMessageShareData({
				title: '高端1v1精准匹配，全新脱单模式闪亮登场',
				desc: '优质会员真实靠谱，资深红娘全程服务，精准高效速配脱单，数据分析深度认知，安全放心高度保密！',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/top.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
			// 分享到朋友圈
			window.wx.updateTimelineShareData({
				title: '高端1v1精准匹配，全新脱单模式闪亮登场',
				link: url,
				imgUrl: 'https://static.hamu.site/blind/top.png',
				success: (res:any) => {
				},
				cancel: (err:any) => {
				}
			});
		});
	}
	changePriceType(priceType: number): void {
		this.setState({
			priceType
		});
	}

	goBack(): void {
		window.location.href = '/';
	}

	render() {
		const {
			redFreeConfigList,
			redFeeTypeList
		} = this.props;
		const { priceType } = this.state;
		return (
			<Container className="MatchmakerDetail" disableGutters>
				<Container className="matchmaker-content" disableGutters>
					<img className="bg" src={require('@images/matchmaker/top.png')} alt="" />
					<Container className="details">
						<img className="girl" src={require('@images/matchmaker/girl.png')} alt="" />
						<div className="tips">程序员脱单实验室一直以来的愿景就是为每一个程序员找到自己的另一半。</div>
						<div className="tips">随着程序员脱单实验室的发展，越来越多优秀的用户订阅我们，信任我们。根据优秀的你们的需求，我们推出了高端定制化服务--1v1红娘服务。</div>
						<div className="tips laster">
							程序员脱单实验室1v1红娘服务将为优质用户精准匹配心仪的另一半，帮助优秀但忙碌的你提升脱单效率，为不愿将就的你找到甜甜的爱情。
							<img className="flower" src={require('@images/matchmaker/flower.png')} alt="" />
						</div>
						<div className="title">{'1V1红娘服务适合人群 >>>'}</div>

						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							择偶目标明确，只想看心仪的对象类型，不想浪费时间看其他类型。
						</div>

						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							工作已经很累了，不想相亲再过度耗费精力和时间，希望能高效脱单。
						</div>

						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							生活圈子比较小，身边没有合适的人，想要眼光更辽阔，不将就，坚信这个世界上一定有适合自己的、对的人。
						</div>

						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							非常在意个人隐私，不想公开发布个人信息，喜欢一对一沟通。
						</div>

						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							性格内向，不知道如何与心仪对象开启聊天话题，甚至在以前的交往中，也曾因约会不顺利而导致恋情无疾而终。
						</div>

						<div className="tips star laster">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							或者你只是单纯想要在与心仪对象刚开始接触时，有个冷静的旁观者能与你一起分享一下心情，分析一下情况。
							<div className="icon">
								<img className="sanjiao sanjiao1" src={require('@images/matchmaker/sanjiao1.png')} alt="" />
								<img className="sanjiao sanjiao2" src={require('@images/matchmaker/sanjiao2.png')} alt="" />
							</div>
						</div>
						<div className="title">
							{'购买1v1红娘服务你会获得 >>>'}
							<img className="hand" src={require('@images/matchmaker/hand.png')} alt="" />
						</div>
						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							为你推荐一位或 数位精准匹配的对象。
						</div>
						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							帮你破冰，轻松开启你的浪漫故事。
						</div>
						<div className="tips star laster">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							根据用户具体情况，如果你需要，可以为你进行更多的个性化贴心服务。可以细致到：聊到什么程度适合约见面，去哪里约会比较好，
							要穿什么样的衣服，今天的约会看不明白ta的态度需要帮忙分析并寻求进一步发展……当然，如果你只是想要倾诉追寻爱情的心情，也随时有人倾听。
						</div>
						<div className="title">{'1v1红娘服务流程 >>>'}</div>
						<div className="tips star">
							<div className="index">1</div>
							<div>
								<div className="index-title">
									扫码报名
									<div className="dots">
										<img src={require('@images/matchmaker/dots.png')} alt="" />
										<img src={require('@images/matchmaker/dots.png')} alt="" />
									</div>
								</div>
								<div>有1v1精准服务需求的嘉宾可以扫码购买服务，支付完成后扫码添加红娘微信。</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">2</div>
							<div>
								<div className="index-title">
									确认需求
									<div className="dots">
										<img src={require('@images/matchmaker/dots.png')} alt="" />
										<img src={require('@images/matchmaker/dots.png')} alt="" />
									</div>
								</div>
								<div>1v1红娘会和您确认择偶标准并进行学历认证。</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">3</div>
							<div>
								<div className="index-title">
									达成合作
									<div className="dots">
										<img src={require('@images/matchmaker/dots.png')} alt="" />
										<img src={require('@images/matchmaker/dots.png')} alt="" />
									</div>
								</div>
								<div>
									双方对整体和细节服务内容达成一致后，将开始合作。
									<img className="flower index" src={require('@images/matchmaker/flower.png')} alt="" />
								</div>
							</div>
						</div>
						<div className="tips star">
							<div className="index">4</div>
							<div>
								<div className="index-title">
									提供服务
									<div className="dots">
										<img src={require('@images/matchmaker/dots.png')} alt="" />
										<img src={require('@images/matchmaker/dots.png')} alt="" />
									</div>
								</div>
								<div>红娘团队会严格按照服务内容，推荐约定数量的优质嘉宾并提供后续服务。</div>
							</div>
						</div>
						<div className="tips star laster">
							<div className="index">5</div>
							<div>
								<div className="index-title">
									持续跟进
									<div className="dots">
										<img src={require('@images/matchmaker/dots.png')} alt="" />
										<img src={require('@images/matchmaker/dots.png')} alt="" />
									</div>
								</div>
								<div>
									1v1红娘将全程跟进服务，及时解决问题，保证服务质量。
									<img className="box-img" src={require('@images/matchmaker/box.png')} alt="" />
								</div>
							</div>
						</div>
						<div className="title">{'服务简介 >>>'}</div>
						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							服务周期：1-3个月内，高效为您提供精准服务。
						</div>
						<div className="tips star">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							<div>
								服务费用：
								<div>
									推荐
									<span className="red-color">一位</span>
									符合您择偶条件的优质对象，并持续跟进服务
									<span className="red-color">{redFeeTypeList.red_one.price}元</span>
									。
								</div>
								<div>
									推荐
									<span className="red-color">三位</span>
									符合您择偶条件的优质对象，并持续跟进服务
									<span className="red-color">{redFeeTypeList.red_three.price}元</span>
									。
								</div>
							</div>
						</div>
						<div className="tips star last-child">
							<img src={require('@images/matchmaker/star.png')} alt="" />
							优质推荐：根据用户需求可以选择一次性推荐或者在服务周期内依次推荐。
						</div>
					</Container>
					<Container className="details qrcode">
						<div className="box-lines">
							<div />
							<div />
						</div>
						<div className="qrcode-box">
							{
								redFreeConfigList.detail_qrcode
									? <img src={redFreeConfigList.detail_qrcode} alt="" />
									: <Skeleton animation="wave" variant="rect" width={112} height={112} />
							}
							<div>支付后扫码添加红娘微信</div>
						</div>
					</Container>
					{/* <Container className="details price-container" disableGutters>
						<Grid className="price-box" container spacing={3}>
							<Grid onClick={() => this.changePriceType(0)} item xs={6} className={`info-item left ${priceType === 0 && 'active'}`}>
								<div className="price">
									{redFeeTypeList.red_one.price}
									元
								</div>
								<div>单次体验价格</div>
							</Grid>
							<Grid onClick={() => this.changePriceType(1)} item xs={6} className={`info-item ${priceType === 1 && 'active'}`}>
								<div className="price">
									{redFeeTypeList.red_three.price}
									元
								</div>
								<div>服务期内推荐{redFeeTypeList.red_three.count}次</div>
							</Grid>
						</Grid>
					</Container> */}
					{/* 活动折扣 */}
					<Container className="details price-container" disableGutters>
						<Grid className="price-box" container spacing={3}>
							<Grid onClick={() => this.changePriceType(0)} item xs={6} className={`info-item left discount ${priceType === 0 && 'active'}`}>
								<p>7.8折</p>
								<div style={{ textDecoration: 'line-through'}}>999</div>
								<div className="price-activity">
									{redFeeTypeList.red_one.price}元/1次 
								</div>
							</Grid>
							<Grid onClick={() => this.changePriceType(1)} item xs={6} className={`info-item  left discount ${priceType === 1 && 'active'}`}>
								<p>7.8折</p>
								<div style={{ textDecoration: 'line-through'}}>1999</div>
								<div className="price-activity">
									{redFeeTypeList.red_three.price}元/3次
								</div>
							</Grid>
						</Grid>
					</Container>
				</Container>
				<div className="footer">
					<Button disableRipple className="home-btn btns" onClick={() => this.goBack()}>
						<img className="footer-home-img" src={require('@images/matchmaker/home.png')} alt="" />
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className="matchmaker-btn"
						onClick={() => this.setRedPayment()}
					>
						立即购买
					</Button>
				</div>
			</Container>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => ({
	wechatConfig: state.wechatConfig.data,
	redFeeTypeList: state.redFeeTypeList.data,
	redFreeConfigList: state.redFreeConfigList.data
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	...WeChatConfigActions,
	...UserDetailDataActions
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MatchmakerDetail));
