/**
 * Action types
 */
export enum HomeDataTypes {
	LOAD_HOMEDATA_REQUEST = '@home/LOAD_HOMEDATA_REQUEST',
	LOAD_HOMEDATA_SUCCESS = '@home/LOAD_HOMEDATA_SUCCESS',
	LOAD_HOMEDATA_FAILURE = '@home/LOAD_HOMEDATA_FAILURE'
}
/**
 * Data types
 */
export interface HomeData {
	is_member: boolean;
	hasNext: boolean;
	hasPrev: boolean;
	list: any[] | null;
	page: number;
	total: number;
	gender: number;
}
/**
 * State types
 */

export interface HomeDataState {
    readonly data: HomeData;
    readonly loading: boolean;
    readonly error: boolean;
}
