import React, { useRef } from 'react';
import './_ProvinceLinkage.scss';

import {
	Box, Button, Container, Grid, List, ListItem
} from '@material-ui/core';
import { ArrowDropUp } from '@material-ui/icons';
import { cityListData } from '@libs/cityDataUtil';

/**
 * Data types
 */
interface SearchKeywords {
	country: string;
	province:{
		name: string;
		code: string;
	} ;
	city: {
		name: string;
		code: string;
	} ;
}

interface Props {
	searchKeywords: SearchKeywords;
	changeSearch: Function;
	showSearch: Function;
	showProvin: boolean;
}
export default function ProvinceLinkage({
	searchKeywords, changeSearch, showProvin, showSearch
}: Props) {
	// const [openTap, setOpenTap] = useState<boolean>(false);
	// const [keywords, setkeywords] = useState<any>(searchKeywords);
	const provinceRef:any = useRef<HTMLDivElement>(null);
	const cityRef:any = useRef<HTMLDivElement>(null);
	function handleChange(event: any) {
		const { dataset } = event.currentTarget;
		const { country, province } = searchKeywords;
		if (dataset.name === '不限') {
			// dataset.name = '';
			if (dataset.code || dataset.code !== '') {
				dataset.code = '';
			}
		}
		switch (dataset.type) {
			case 'country':
				searchKeywords = {
					country: dataset.name === '不限' ? '' : dataset.name,
					province: {
						name: '不限', // 省的名称
						code: '' // 省的code
					},
					city: {
						name: '不限', // 市的名称
						code: '' // 省的code
					}
				};
				provinceRef.current.scrollTop = 0;
				if (cityRef.current) {
					cityRef.current.scrollTop = 0;
				}
				break;
			case 'province':
				searchKeywords = {
					country,
					province: {
						name: dataset.name,
						code: dataset.code
					},
					city: {
						name: '不限', // 市的名称
						code: '' // 省的code
					}
				};
				if (cityRef.current) {
					cityRef.current.scrollTop = 0;
				}
				break;
			case 'city':
				searchKeywords = {
					country,
					province: {
						name: province.name,
						code: province.code
					},
					city: {
						name: dataset.name, // 市的名称
						code: dataset.code // 省的code
					}
				};
				break;
		}
		if (dataset.type == 'city' || country == '海外' && dataset.type == 'province') {
			changeSearch(searchKeywords, true);
		} else {
			changeSearch(searchKeywords);
		}
	}
	return (
		<Box
			className={
				showProvin
					? 'ProvinceLinkage active'
					: 'ProvinceLinkage'
			}
		>
			<div className="p-top">
				<Button className="tap" onClick={() => showSearch(!showProvin)}>
					<span className="current-name ellipsis-line">{searchKeywords.country ? searchKeywords.country : '不限'}</span>
					<ArrowDropUp className="tap-icon" style={{ transform: showProvin ? 'rotate(0deg)' : 'rotate(180deg)' }} />
				</Button>
				<Button className="tap" onClick={() => showSearch(!showProvin)}>
					<span className="current-name ellipsis-line">{searchKeywords.province.name ? searchKeywords.province.name : '不限'}</span>
					<ArrowDropUp className="tap-icon" style={{ transform: showProvin ? 'rotate(0deg)' : 'rotate(180deg)' }} />
				</Button>
				<Button className="tap" onClick={() => showSearch(!showProvin)}>
					<span className="current-name ellipsis-line">{searchKeywords.city.name ? searchKeywords.city.name : '不限'}</span>
					<ArrowDropUp className="tap-icon" style={{ transform: showProvin ? 'rotate(0deg)' : 'rotate(180deg)' }} />
				</Button>
			</div>
			<Container
				className={
					showProvin
						? 'container-box active'
						: 'container-box'
				}
				disableGutters
			>
				<Box className="shadow-container" onClick={() => showSearch(false)} />
				<Grid className="grid-box" container spacing={3}>
					<Grid className="grid-column grid-country" item xs={4}>
						<List className="list-box">
							{
								Object.keys(cityListData).length > 0
								&& Object.keys(cityListData)
									.map(
										(key: string) => (
											<ListItem
												data-type="country"
												data-name={cityListData[key].name}
												className={
													cityListData[key].name === searchKeywords.country
														|| (searchKeywords.country === '' && cityListData[key].name === '不限')
														? 'city-li country-li active'
														: 'city-li country-li'
												}
												key={cityListData[key].name}
												onClick={handleChange}
											>
												{cityListData[key].name}
											</ListItem>
										)
									)
							}
						</List>
					</Grid>
					{
						searchKeywords.country === '中国'
						|| !searchKeywords.country
							? [
								<Grid className="grid-column" key="col-1" item xs={4}>
									<div ref={provinceRef} className="list-box">
										<List>
											{
												Object.keys(cityListData).length > 0
												&& Object.keys(cityListData[searchKeywords.country].province)
													.map(
														(key: string) => (
															<ListItem
																className={
																	cityListData[searchKeywords.country].province[key]
																	=== searchKeywords.province.name
																	|| (searchKeywords.province.name === ''
																		&& cityListData[searchKeywords.country].province[key] === '不限'
																	)
																		? 'city-li city active'
																		: 'city-li city'
																}
																key={cityListData[searchKeywords.country].province[key]}
																data-type="province"
																data-name={cityListData[searchKeywords.country].province[key]}
																data-code={key}
																onClick={handleChange}
															>
																{cityListData[searchKeywords.country].province[key]}
															</ListItem>
														)
													)
											}
										</List>
									</div>
								</Grid>,
								<Grid className="grid-column" key="col-2" item xs={4}>
									<div ref={cityRef} className="list-box">
										<List>
											{
												Object.keys(cityListData).length > 0
										&& Object.keys(cityListData[searchKeywords.country].city[searchKeywords.province.code])
											.map(
												(key: string) => (
													<ListItem
														className={
															cityListData[searchKeywords.country].city[searchKeywords.province.code][key]
															=== searchKeywords.city.name
															|| (cityListData[searchKeywords.country].city[searchKeywords.province.code][key] === '不限'
																&& searchKeywords.city.name === ''
															)
																? 'city-li city active'
																: 'city-li city'
														}
														key={cityListData[searchKeywords.country].city[searchKeywords.province.code][key]}
														data-type="city"
														data-name={cityListData[searchKeywords.country].city[searchKeywords.province.code][key]}
														data-code={searchKeywords.province.code}
														onClick={handleChange}
													>
														{cityListData[searchKeywords.country].city[searchKeywords.province.code][key]}
													</ListItem>
												)
											)
											}
										</List>
									</div>
								</Grid>
								]
							: [
								<Grid className="grid-column" key="col-1" item>
									<div className="list-box" ref={provinceRef}>
										<List>
											{
												Object.keys(cityListData).length > 0
										&& Object.keys(cityListData[searchKeywords.country].province)
											.map(
												(key: string) => (
													<ListItem className="city-li city-li-flex" id={key} key={`${key}_box`}>
														<div>{key}</div>
														<List>
															{
																cityListData[searchKeywords.country].province[key].length > 0
																	&& cityListData[searchKeywords.country].province[key].map((cityObj:any) => (
																		<ListItem
																			className={
																				cityObj.name === searchKeywords.province.name
																				|| (cityObj.name === '不限' && searchKeywords.province.name === '')
																					? 'city-li city active'
																					: 'city-li city'
																			}
																			key={cityObj.code}
																			data-type="province"
																			data-name={cityObj.name}
																			data-code={cityObj.code}
																			onClick={handleChange}
																		>
																			{cityObj.name}
																		</ListItem>
																	))
															}
														</List>
													</ListItem>

												)
											)
											}
										</List>
									</div>
								</Grid>,
								<Grid className="grid-column grid-tag" key="col-2">
									<List className="list-box">
										{
											Object.keys(cityListData).length > 0
											&& Object.keys(cityListData[searchKeywords.country].tag).map(
												(key: string) => (
													<ListItem className="city-li" key={cityListData[searchKeywords.country].tag[key]}>
														<a href={`#${cityListData[searchKeywords.country].tag[key]}`}>
															{cityListData[searchKeywords.country].tag[key]}
														</a>
													</ListItem>
												)
											)
										}
									</List>
								</Grid>
						 ]

					}
				</Grid>
			</Container>
		</Box>
	);
}
