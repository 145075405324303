import { Reducer } from 'redux';
import { HomeDataState, HomeDataTypes } from './types';

const INITIAL_STATE: HomeDataState = {
	data: {
		is_member: false,
		hasNext: true,
		hasPrev: true,
		list: [],
		page: 0,
		total: 0,
		gender: 1
	},
	error: false,
	loading: false
};

const reducer: Reducer<HomeDataState> = (state = INITIAL_STATE, aciton) => {
	switch (aciton.type) {
		case HomeDataTypes.LOAD_HOMEDATA_REQUEST:
			return {
				...state, loading: true
			};
		case HomeDataTypes.LOAD_HOMEDATA_SUCCESS:
			if (!aciton.payload.data.tap) {
				aciton.payload.data.list = state.data.list?.concat(aciton.payload.data.list);
			}
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case HomeDataTypes.LOAD_HOMEDATA_FAILURE:
			return {
				...state, loading: false, error: true
			};
		default:
			return state;
	}
};

export default reducer;
