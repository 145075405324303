export const formatDate = function (date: Date, format?: string, utc?: string) {
	if (!date) {
		date = new Date();
	}

	if (!format) {
		format = 'YYYY-MM-DD hh:mm:ss.SSS';
	}

	const result = [];

	let looper = 0;
	while (looper < format.length) {
		switch (format[looper]) {
			case 'Y': {
				if (format[looper + 1] === 'Y') {
					if (format[looper + 2] === 'Y' && format[looper + 3] === 'Y') {
						result.push(
							(
								`000${utc ? date.getUTCFullYear() : date.getFullYear()}`
							).slice(-4),
						);
						looper += 4;
					} else {
						result.push(
							(
								`0${
									(utc ? date.getUTCFullYear() : date.getFullYear()) % 100}`
							).slice(-2),
						);
						looper += 2;
					}
				} else {
					result.push(`${utc ? date.getUTCFullYear() : date.getFullYear()}`);
					looper += 1;
				}
				break;
			}

			case 'M': {
				if (format[looper + 1] === 'M') {
					result.push(
						(`0${(utc ? date.getUTCMonth() : date.getMonth()) + 1}`).slice(
							-2,
						),
					);
					looper += 2;
				} else {
					result.push(`${(utc ? date.getUTCMonth() : date.getMonth()) + 1}`);
					looper += 1;
				}
				break;
			}

			case 'D': {
				if (format[looper + 1] === 'D') {
					result.push(
						(`0${utc ? date.getUTCDate() : date.getDate()}`).slice(-2),
					);
					looper += 2;
				} else {
					result.push(`${utc ? date.getUTCDate() : date.getDate()}`);
					looper += 1;
				}
				break;
			}

			case 'h': {
				if (format[looper + 1] === 'h') {
					result.push(
						(`0${utc ? date.getUTCHours() : date.getHours()}`).slice(-2),
					);
					looper += 2;
				} else {
					result.push(`${utc ? date.getUTCHours() : date.getHours()}`);
					looper += 1;
				}
				break;
			}

			case 'm': {
				if (format[looper + 1] === 'm') {
					result.push(
						(`0${utc ? date.getUTCMinutes() : date.getMinutes()}`).slice(-2),
					);
					looper += 2;
				} else {
					result.push(`${utc ? date.getUTCMinutes() : date.getMinutes()}`);
					looper += 1;
				}
				break;
			}

			case 's': {
				if (format[looper + 1] === 's') {
					result.push(
						(`0${utc ? date.getUTCSeconds() : date.getSeconds()}`).slice(-2),
					);
					looper += 2;
				} else {
					result.push(`${utc ? date.getUTCSeconds() : date.getSeconds()}`);
					looper += 1;
				}
				break;
			}

			case 'S': {
				if (format[looper + 1] === 'S' && format[looper + 2] === 'S') {
					result.push(
						(
							`00${utc ? date.getUTCMilliseconds() : date.getMilliseconds()}`
						).slice(-3),
					);
					looper += 3;
				} else {
					result.push(
						`${utc ? date.getUTCMilliseconds() : date.getMilliseconds()}`,
					);
					looper += 1;
				}
				break;
			}

			case '"':
			case '\'': {
				let offset = 1;
				while (
					format[looper + offset] !== format[looper]
					&& looper + offset < format.length
				) {
					if (format[looper + offset] === '\\') {
						result.push(format[looper + offset + 1]);
						offset += 2;
					} else {
						result.push(format[looper + offset]);
						looper += 1;
					}
				}
				looper += offset + 1;
				break;
			}

			default: {
				result.push(format[looper]);
				looper += 1;
				break;
			}
		}
	}

	return result.join('');
};
