import { action } from 'typesafe-actions';
import {
	UserDetailDataTypes, FeeTypeTypes, UserDetailData, FeeTypeList, RedFeeTypeTypes, RedFeeTypeList, RedFreeConfigTypes, RedFreeConfigList
} from './types';

/**
 * 用户详情
 */
export const loadUserDetailRequest = (p: string) => action(UserDetailDataTypes.LOAD_USER_DETAIL_REQUEST, p);
export const loadUserDetailSuccess = (data: UserDetailData) => action(UserDetailDataTypes.LOAD_USER_DETAIL_SUCCESS, { data });
export const loadUserDetailFailure = () => action(UserDetailDataTypes.LOAD_USER_DETAIL_FAILURE);

/**
 * 个人信息支付列表
 */
export const loadGetFeeTypeRequest = () => action(FeeTypeTypes.LOAD_GET_FEETYPE_REQUEST);
export const loadGetFeeTypeSuccess = (data: FeeTypeList) => action(FeeTypeTypes.LOAD_GET_FEETYPE_SUCCESS, { data });
export const loadGetFeeTypeFailure = () => action(FeeTypeTypes.LOAD_GET_FEETYPE_FAILURE);
/**
 * 红娘支付列表
 */
export const loadGetRedFeeTypeRequest = () => action(RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_REQUEST);
export const loadGetRedFeeTypeSuccess = (data: RedFeeTypeList) => action(RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_SUCCESS, { data });
export const loadGetRedFeeTypeFailure = () => action(RedFeeTypeTypes.LOAD_GET_RED_FEETYPE_FAILURE);

/**
 * 红娘支付列表
 */
export const loadGetRedFeeConfigRequest = () => action(RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_REQUEST);
export const loadGetRedFeeConfigSuccess = (data: RedFreeConfigList) => action(RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_SUCCESS, { data });
export const loadGetRedFeeConfigFailure = () => action(RedFreeConfigTypes.LOAD_GET_RFEE_REECONFIG_SUCCESS);
