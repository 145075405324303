import React, { Component } from 'react';
import {
	AppBar, Toolbar, Typography, Button
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import {
	getCustomerInfo, getQuestions, getTokenToUpload, getUserInfoByCode, getCurrentUserInfo, updateCustomerInfo
} from '@service/api';
import * as qiniu from 'qiniu-js';
import * as querystring from 'querystring';
import {
	List,
	InputItem,
	Switch,
	DatePicker,
	Picker,
	TextareaItem,
	WingBlank,
	ImagePicker,
	WhiteSpace,
	Modal,
	Radio,
	Toast
} from 'antd-mobile';
import './_index.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import cityData from '@libs/cityFormat';

interface PrivacyInformation {
	微信号: string;
	手机号: number | string;
    QQ号: number | string,
    年收入: number | string,
    是否购房: string,
    其他经济条件: string,
    恋爱经历: string,
    家庭背景: string
}

interface IdealObject {
	年龄范围: any[];
	坐标: string;
	外貌: string;
	学历: string;
	性格: string;
	是否有房: string;
	年收入: string;
	补充: string;
}

interface Personal {
	name: string;
	gender: string;
	height: number | string;
	birthday: any;
	education: string;
	job: string;
	living_place: string[];
	hometown: string[];
	is_only: string[];
	only_content: string;
	cover_img: string;
	img_list: string[];
}

interface StringArr {
	label: string;
	value: string;
}
interface State {
	temp: any;
	step: number;
	checked: boolean;
	info: Personal;
	educationList: StringArr[];
	privacy_information: PrivacyInformation;
	introduce: string[];
	ideal_object: IdealObject;
	ageList: any[];
	titleList: string[];
	cityData: any[];
	questionModal: boolean;
	questions_answers: any[];
	qaIndex: number;
	questionChecked: number;
	questionList: any[];
}

type Props = RouteComponentProps;
class WriteInformation extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			temp: {},
			step: 0,
			checked: true,
			questionChecked: -1,
			questionModal: false,
			educationList: [
				{
					label: '专科',
					value: '专科'
				},
				{
					label: '本科',
					value: '本科'
				},
				{
					label: '硕士',
					value: '硕士'
				},
				{
					label: '博士',
					value: '博士'
				}
			],
			info: {
				name: '',
				gender: '男',
				height: '',
				birthday: new Date(1990, 0, 1, 0, 0, 0),
				education: '',
				job: '',
				living_place: [],
				hometown: [],
				is_only: ['是'],
				only_content: '',
				cover_img: '',
				img_list: []
			},
			privacy_information: {
				微信号: '',
				手机号: '',
				QQ号: '',
				年收入: '',
				家庭背景: '',
				是否购房: '',
				其他经济条件: '',
				恋爱经历: ''
			},
			qaIndex: 0,
			introduce: ['', '', ''],
			ideal_object: {
				坐标: '',
				外貌: '',
				学历: '',
				年龄范围: [0, 99],
				性格: '',
				是否有房: '',
				年收入: '',
				补充: ''
			},
			ageList: [],
			titleList: ['核心资料', '自我介绍', '理想对象', '隐私信息', '问答环节'],
			cityData,
			questionList: [],
			questions_answers: [{ A: '', Q: '' }, { A: '', Q: '' }, { A: '', Q: '' }]
		};
	}

	async componentDidMount() {
		const { location: { search } } = this.props;
		const queryArr:string[] = search.split('?');
		if (queryArr.length === 2) {
			const obj:any = querystring.parse(queryArr[1]);
			const { code } = obj;
			if(code) {
				await getUserInfoByCode(code);
			}
		}
		await getCurrentUserInfo();
		const { match: { params } } :any = this.props;

		if (params && params.step) {
			this.setState({
				step: parseInt(params.step)
			});
		}
		document.title = '脱单实验室';
		let ageList: any[] = [];

		ageList = this.loopAge(ageList);

		ageList = ageList.map((age:any) => {
			age.children = [];
			age.chilren = this.loopAge(age.children, age.value + 1, 46);
			return age;
		});

		this.setState({
			ageList
		});
		getCustomerInfo().then((res:any) => {
			this.updateRes(res);
		});

		getQuestions().then((res) => {
			const questionList: { content: any; }[] = [];
			Object.values(res).forEach((item:any) => {
				questionList.push({
					content: item
				});
			});
			this.setState({
				questionList
			});
		});
	}

	loopAge =(agetList: any[], ageDefault = 18, max = 45) => {
		for (let age = ageDefault; age < max; age++) {
			agetList.push({
				label: `${age}`,
				value: age
			});
		}
		return agetList;
	}

	async preClick() {
		window.scrollTo(0, 0);
		const { match, history } = this.props;
		const { step } = this.state;
		let { url } = match;
		url = url.slice(0, url.length - 1);
		url += step - 1;
		this.setState({
			step: step - 1
		});
		this.updateCustomer();
		history.push(url);
	}

	updateRes(res:any) {
		res.birthday = new Date(res.birthday);
		this.setState({
			temp: res,
			info: Object.assign(this.state.info, res),
			introduce: Object.assign(this.state.introduce, res.introduce),
			questions_answers: Object.assign(this.state.questions_answers, res.questions_answers),
			ideal_object: Object.assign(this.state.ideal_object, res.ideal_object),
			privacy_information: Object.assign(this.state.privacy_information, res.privacy_information)
		});
	}

	async updateCustomer() {
		const { match } = this.props;
		const paramer: any = match.params;
		console.log(paramer);
		const {
			introduce, questions_answers, ideal_object, privacy_information, info
		} = this.state;
		const res = await updateCustomerInfo(Object.assign({},this.state.temp, info, {
			introduce,
			questions_answers,
			ideal_object,
			privacy_information,
			birthday: new Date(info.birthday).getTime(),
			operate_id: this.state.temp.operate_id  || paramer.id,
			"operate_type": this.state.temp.operate_type || "投稿"
		}));
		this.updateRes(res);
	}

	verifCoreData() {
		let { info : { name, height, birthday , living_place, hometown, education,  job, is_only, only_content }, privacy_information: { 微信号 , 年收入 } } = this.state;
		if(!name) {
			Toast.info("请输入姓名",3);
			return false;
		}
		if(!height) {
			Toast.info("请输入身高",3);
			return false;
		}
		if(new Date(birthday).getTime() == 0) {
			Toast.info("请输入出生年月",3);
			return false;
		}
		living_place = living_place.filter(item=> item);
		hometown = hometown.filter(item=> item);
		if(living_place.length == 0) {
			Toast.info("请输入现居地",3);
			return false;
		}
		if(hometown.length == 0) {
			Toast.info("请输入家乡",3);
			return false;
		}
		if(!education) {
			Toast.info("请填写学历",3);
			return false;
		}
		if(!job) {
			Toast.info("请填写职业",3);
			return false;
		}
		if(is_only.includes("否") && !only_content) {
			Toast.info("请填写您的兄弟姐妹情况",3);
			return false;
		}
		if(!微信号) {
			Toast.info("请填写您的微信号",3);
			return false;
		}
		if(!年收入) {
			Toast.info("请填写您的年收入",3);
			return false;
		}
		return true;
	}
	async nextClick() {
		window.scrollTo(0, 0);
		const { step } = this.state;
		if (step === 4) {
			await this.updateCustomer();
			const { match: { params } } :any = this.props;
			window.location.href = `/mp/questionnaire/end/${params.id}`;
			return;
		}
		if(step == 0) {
			let flag = this.verifCoreData();
			if(!flag) {
				return;
			}
		}
		const { match, history } = this.props;
		let { url } = match;
		url = url.slice(0, url.length - 1);
		url += step + 1;
		this.setState({
			step: step + 1
		});
		this.updateCustomer();
		history.push(url);
	}

	onChange = async (files: any, operationType: string, index?: number) => {
		const { info, info: { img_list } } = this.state;
		console.log(files);
		if (operationType === 'remove' && index !== undefined) {
			const imgList = img_list;
			imgList.splice(index, 1);
			this.setState({
				info: { ...info, img_list: imgList }
			});
			return;
		}
		Toast.loading('上传中...', 120);
		const cover_img: any = await this.uploadImg(files[files.length - 1].file);
		this.setState({
			info: { ...this.state.info, img_list: img_list.concat(cover_img) }
		});
		Toast.hide();
	};

	onCoverChange = async (files: any, operationType: string, index?: number) => {
		if (operationType === 'remove') {
			this.setState({
				info: { ...this.state.info, cover_img: '' }
			});
			return;
		}
		Toast.loading('上传中...', 120);
		const cover_img: any = await this.uploadImg(files[0].file);
		this.setState({
			info: { ...this.state.info, cover_img }
		});
		Toast.hide();
	};

	uploadImg = (file: any) => new Promise((resolve, reject) => getTokenToUpload().then((res: any) => {
		qiniu.compressImage(file, {
			noCompressIfLarger: true
		}).then((data: any) => {
			const observable = qiniu.upload(data.dist, res.key, res.token, {
				fname: res.key
			}, {
				useCdnDomain: true,
				region: qiniu.region.z0,
				checkByMD5: true
			});
			observable.subscribe({
				next: () => {},
				error: (err) => {
					reject(err);
				},
				complete: (result) => {
					resolve(`${res.domain}/${result.key}`);
				}
			});
		});
	}))

	renderCoreData = () => {
		const {
			info,
			info: {
				name, gender, birthday, education, job, height, living_place, hometown, is_only, only_content
			},
			educationList,
			cityData,
			privacy_information,
			privacy_information: { 微信号, 年收入 }
		} = this.state;
		return (
			<div className="core__data">
				<List>
					<InputItem
						placeholder="请填写您的姓名"
						value={name}
						onChange={(val) => {
							this.setState({
								info: { ...info, name: val }
							});
						}}
					>
						姓名
					</InputItem>
					<List.Item
						extra={(
							<Switch
								checked={gender == '男'}
								color="#EF5050"
								name={gender}
								onChange={(checked) => {
									console.log(checked);
									this.setState({
										checked,
										info: {
											...info,
											gender: checked ? '男' : '女'
										}
									});
								}}
							/>
						)}
					>
						性别
					</List.Item>
					<InputItem
						placeholder=""
						extra="cm"
						type="number"
						value={`${height == 0 ? '' : height}`}
						onChange={(val) => {
							this.setState({
								info: { ...info, height: Number(val) }
							});
						}}
					>
						身高
					</InputItem>
					<DatePicker
						mode="date"
						title="出生年月"
						extra="请选择出生年月"
						minDate={new Date(1970, 0, 1, 0, 0, 0)}
						value={birthday}
						onChange={(birthday: Date) => {
							this.setState({
								info: { ...info, birthday }
							});
						}}
					>
						<List.Item>出生年月</List.Item>
					</DatePicker>
					<Picker
						extra="请选择现居地"
						data={cityData}
						title="区域"
						value={living_place}
						onOk={(living_place: string[]) => {
							this.setState({
								info: { ...info, living_place }
							});
						}}
					>
						<List.Item arrow="down">现居地</List.Item>
					</Picker>
					<Picker
						extra="请选择家乡"
						data={cityData}
						title="区域"
						value={hometown}
						onOk={(hometown: string[]) => {
							this.setState({
								info: { ...info, hometown }
							});
						}}
					>
						<List.Item arrow="down">家乡</List.Item>
					</Picker>
					<Picker
						data={educationList}
						cols={1}
						extra="请选择学历"
						value={[education]}
						onOk={(val: any) => {
							this.setState({
								info: { ...info, education: val[0] }
							});
						}}
					>
						<List.Item arrow="down">学历</List.Item>
					</Picker>
					<InputItem
						placeholder="请填写您的职业"
						value={job}
						onChange={(val: any) => {
							this.setState({
								info: { ...info, job: val }
							});
						}}
					>
						职业
					</InputItem>
					<InputItem
						placeholder="请填写您的微信号"
						value={微信号}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 微信号: val }
							});
						}}
					>
						微信号
					</InputItem>
					<InputItem
						placeholder=""
						extra="万"
						type="number"
						value={`${年收入 == 0 ? '' : 年收入}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 年收入: Number(val) }
							});
						}}
					>
						年收入
					</InputItem>
					<div className="only_box">
						<Picker
							extra="请选择"
							data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
							title=""
							cols={1}
							value={is_only}
							onOk={(only: string[]) => {
								this.setState({
									info: { ...info, is_only: only }
								});
							}}
						>
							<List.Item arrow="down">是否独生子女</List.Item>
						</Picker>
						{is_only.includes('否') ? (
							<div className="only_box_item">
								<InputItem
									placeholder="请填写您的兄弟姐妹情况"
									value={only_content}
									onChange={(val: any) => {
										this.setState({
											info: { ...info, only_content: val }
										});
									}}
								/>
							</div>
						) : ''}
					</div>
					{/* <InputItem
						placeholder=""
						value={家庭背景}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 家庭背景: val === '是' ? '是独生子女' : val }
							});
						}}
					>
						是否独生子女
					</InputItem> */}
				</List>
			</div>
		);
	};

	renderSelfIntroduction = () => {
		const { introduce, info: { cover_img, img_list } } = this.state;
		return (
			<div className="self__introduction">
				<List renderHeader={() => '性格'}>
					<TextareaItem
						autoHeight
						value={introduce[0]}
						onChange={(val: string | any) => {
							const intro = introduce;
							intro[0] = val;
							this.setState({
								introduce: [...intro]
							});
						}}
					/>
				</List>
				<List renderHeader={() => '兴趣爱好/生活习惯'}>
					<TextareaItem
						autoHeight
						value={introduce[1]}
						onChange={(val: string | any) => {
							const intro = introduce;
							intro[1] = val;
							this.setState({
								introduce: [...intro]
							});
						}}
					/>
				</List>
				<List renderHeader={() => '其他'}>
					<TextareaItem
						autoHeight
						value={introduce[2]}
						onChange={(val: string | any) => {
							const intro = introduce;
							intro[2] = val;
							this.setState({
								introduce: [...intro]
							});
						}}
					/>
				</List>
				<List renderHeader={() => '上传照片'}>
					<WingBlank>
						<p className="img">封面照片</p>
						<ImagePicker
							files={cover_img ? [{
								url: cover_img
							}] : []}
							onChange={this.onCoverChange}
							selectable={!cover_img}
						/>
						<p className="img">文中照片（最多上传三张）</p>
						<ImagePicker
							files={img_list.map((url: string) => ({
								url
							}))}
							onChange={this.onChange}
							selectable={img_list.length < 3}
						/>
					</WingBlank>
				</List>
			</div>
		);
	};

	renderIdealObject = () => {
		const {
			ideal_object, info, ideal_object: {
				年龄范围, 外貌, 性格, 学历, 坐标, 是否有房, 年收入, 补充
			}, ageList, introduce
		} = this.state;

		return (
			<div className="ideal__object">
				<List>
					<Picker
						data={ageList}
						extra="请选择年龄范围"
						format={(labels) => labels.join('-')}
						cols={2}
						value={年龄范围}
						onOk={(val: any) => {
							this.setState({
								ideal_object: { ...ideal_object, 年龄范围: val }
							});
						}}
					>
						<List.Item>年龄范围</List.Item>
					</Picker>
				</List>
				<div className="private_box_house">
					<Picker
						extra="请选择"
						data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
						title=""
						cols={1}
						value={[是否有房]}
						onOk={(value: string[]) => {
							this.setState({
								ideal_object: { ...ideal_object, 是否有房: value[0] }
							});
						}}
					>
						<List.Item arrow="down">是否有房</List.Item>
					</Picker>
				</div>
				<List renderHeader={() => '外貌要求'}>
					<TextareaItem
						autoHeight
						value={外貌}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 外貌: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '性格要求'}>
					<TextareaItem
						autoHeight
						value={性格}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 性格: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '学历要求'}>
					<TextareaItem
						autoHeight
						value={学历}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 学历: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '坐标要求'}>
					<TextareaItem
						autoHeight
						value={坐标}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 坐标: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '年收入要求'}>
					<InputItem 
							className="ideal_object_box"
							placeholder=""
							type="number"
							value={`${年收入}`}
							onChange={(val) => {
								this.setState({
									ideal_object: { ...ideal_object, 年收入: val }
								});
							}}
					/>
				</List>
				<List renderHeader={() => '补充信息'}>
					<TextareaItem
						autoHeight
						value={补充}
						onChange={(val: string | any) => {
							this.setState({
								ideal_object: { ...ideal_object, 补充: val }
							});
						}}
					/>
				</List>
			</div>
		);
	};

	renderPrivacyInfo = () => {
		const {
			introduce, info, privacy_information,
			privacy_information: {
				手机号, QQ号, 年收入, 是否购房, 其他经济条件, 恋爱经历, 家庭背景
			}
		} = this.state;
		return (
			<div className="privacy__info">
				<List>
					<InputItem
						placeholder=""
						type="number"
						value={`${手机号}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, 手机号: Number(val) }
							});
						}}
					>
						手机号
					</InputItem>
					<InputItem
						className="QQ"
						type="number"
						placeholder=""
						value={`${QQ号}`}
						onChange={(val) => {
							this.setState({
								privacy_information: { ...privacy_information, QQ号: Number(val) }
							});
						}}
					>
						QQ号
					</InputItem>
				</List>
				<div className="private_box_house">
					<Picker
						extra="请选择"
						data={[{ value: '是', label: '是' }, { value: '否', label: '否' }]}
						title=""
						cols={1}
						value={[是否购房]}
						onOk={(value: string[]) => {
							this.setState({
								privacy_information: { ...privacy_information, 是否购房: value[0] }
							});
						}}
					>
						<List.Item arrow="down">是否购房</List.Item>
					</Picker>
				</div>
				<List renderHeader={() => '其他经济条件'}>
					<TextareaItem
						autoHeight
						value={其他经济条件}
						onChange={(val: string | any) => {
							this.setState({
								privacy_information: { ...privacy_information, 其他经济条件: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '恋爱经历'}>
					<TextareaItem
						autoHeight
						value={恋爱经历}
						onChange={(val: string | any) => {
							this.setState({
								privacy_information: { ...privacy_information, 恋爱经历: val }
							});
						}}
					/>
				</List>
				<List renderHeader={() => '家庭背景'}>
					<TextareaItem
						autoHeight
						value={家庭背景}
						onChange={(val: string | any) => {
							this.setState({
								privacy_information: { ...privacy_information, 家庭背景: val }
							});
						}}
					/>
				</List>
			</div>
		);
	}

	onWrapTouchStart = (e: any) => {
		// fix touch to scroll background page on iOS
		if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
			return;
		}
		let pNode = null;
		const matchesSelector = e.target.matches || e.target.webkitMatchesSelector || e.target.mozMatchesSelector || e.target.msMatchesSelector;
		while (e.target) {
			if (matchesSelector.call(e.target, '.am-modal-content')) {
				pNode = e.target;
			}
			e.target = e.target.parentElement;
		}

		if (!pNode) {
			e.preventDefault();
		}
	}

	renderQuestion = () => {
		const {
			questions_answers, questionModal, qaIndex, questionList, questionChecked
		} = this.state;
		return (
			<div className="question">
				{
					questions_answers.map((qa, index) => (
						<List key={`${index}qa`}>
							<WhiteSpace size="lg" />
							<List.Item
								arrow="down"
								onClick={() => {
									this.setState({
										questionModal: true,
										qaIndex: index,
										questionChecked: -1
									});
								}}
							>
								{ qa.Q ? qa.Q : `请选择问题${index === 0 ? '一' : (index === 1 ? '二' : '三')}` }
							</List.Item>
							<TextareaItem
								autoHeight
								value={qa.A}
								onChange={(val: string | any) => {
									const QA = questions_answers;
									QA[index].A = val;
									this.setState({
										questions_answers: QA
									});
								}}
							/>
						</List>
					))
				}
				<Modal
					visible={questionModal}
					transparent
					maskClosable
					onClose={() => {
						this.setState({
							questionModal: false
						});
					}}
					title="请选择一个问题进行回答"
					wrapProps={{ onTouchStart: this.onWrapTouchStart }}
				>
					<div className="modal__question">
						<List>
							{
								questionList.map((question, index) => (
									<div key={question.content} className="question__checked">
										<Radio
											className="my-radio"
											checked={questionChecked === index}
											onChange={() => {
												const QA = questions_answers;
												QA[qaIndex].Q = question.content;
												this.setState({
													questionModal: false,
													questions_answers: QA,
													questionChecked: index
												});
											}}
										/>
										<List.Item wrap>
											{ question.content }
										</List.Item>
									</div>
								))
							}
						</List>
					</div>
				</Modal>
			</div>
		);
	}

	render() {
		const { step, titleList } = this.state;

		return (
			<div className="write" key={step}>
				<AppBar position="fixed">
					<Toolbar className="app__header">
						{step === 0 ? (
							<ArrowBackIos onClick={() => {
								const { match: { params } } :any = this.props;
								window.location.href = `/mp/questionnaire/start/${params.id}`;
							}}
							/>
						) : (
							<Button
								onClick={() => this.preClick()}
								className="next__btn"
							>
								上一步
							</Button>
						)}
						<Typography className="title">{ titleList[step] }</Typography>
						<Button
							onClick={() => this.nextClick()}
							className="next__btn"
						>
							{ step === 4 ? '保存' : '下一步' }
						</Button>
					</Toolbar>
				</AppBar>
				<div className="tips">
					填写基本资料之后，我们的编辑将会与你联系，核心资料必填，其他资料选填。
				</div>
				{ step === 0 ? this.renderCoreData() : null}
				{ step === 1 ? this.renderSelfIntroduction() : null}
				{ step === 2 ? this.renderIdealObject() : null }
				{ step === 3 ? this.renderPrivacyInfo() : null }
				{ step === 4 ? this.renderQuestion() : null }
			</div>
		);
	}
}

export default withRouter(WriteInformation);
