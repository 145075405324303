const cityData = require("@libs/city");
delete cityData.province_list["900000"];
const allCity = cityData.city_list;
const provinceList = cityData.province_list;
const municipality = {
	北京: "北京市",
	天津: "天津市",
	上海: "上海市",
	重庆: "重庆市",
};
const Firstchildren = [];

Object.keys(provinceList).map((province, index) => {
	province = `${province}`;
	if (municipality[provinceList[province]]) {
		provinceList[province] = municipality[provinceList[province]];
	}
	else if(!provinceList[province].includes("自治区")){
		provinceList[province] = `${provinceList[province]}省`;
	}
  Firstchildren.push({
		label: provinceList[province],
		value: provinceList[province],
		children: [],
	});
	const provinceId = province.slice(0, 2);
	Object.keys(allCity).map((city) => {
		const cityId = city.slice(0, 2);
		if (provinceId == cityId) {
			Firstchildren[index].children.push({
				label: allCity[city],
				value: allCity[city],
			});
		}
	});
});

const cityFormatData = [
	{
		label: "中国",
		value: "中国",
		children: Firstchildren
	},
];

export default cityFormatData;
