import { combineReducers } from 'redux';
import repositories from './repositories';
import wechat from './wechat';
import homeData from './home';
import detail from './detail';
import mine from './mine';

export default combineReducers({
	repositories,
	wechatConfig: wechat.reducer,
	wechatSetPayment: wechat.paymentReducer,
	homeData,
	userDetailData: detail.reducer,
	feeTypeList: detail.feeTypeReducer,
	redFeeTypeList: detail.redFeeTypeReducer,
	redFreeConfigList: detail.redFeeConfigReducer,
	wechatUserInfo: mine.reducer,
	currentUserInfo: mine.currentUserReducer,
	weChatPaymentStatus: wechat.paymentStatusReducer
});
