import { Reducer } from 'redux';
import { RepositoriesState, RepositoriesTypes } from './types';

const INITIAL_STATE: RepositoriesState = {
	data: [],
	error: false,
	loading: false
};

const reducer: Reducer<RepositoriesState> = (state = INITIAL_STATE, aciton) => {
	switch (aciton.type) {
		case RepositoriesTypes.LOAD_REQUEST:
			return {
				...state, loading: true
			};
		case RepositoriesTypes.LOAD_SUCCESS:
			return {
				...state, loading: false, error: false, data: aciton.payload.data
			};
		case RepositoriesTypes.LOAD_FAILURE:
			return {
				...state, loading: false, error: true, data: []
			};
		default:
			return state;
	}
};

export default reducer;
