/**
 * Action types
 */
export enum UserDetailDataTypes {
	LOAD_USER_DETAIL_REQUEST = '@detail/LOAD_USER_DETAIL_REQUEST',
	LOAD_USER_DETAIL_SUCCESS = '@detail/LOAD_USER_DETAIL_SUCCESS',
	LOAD_USER_DETAIL_FAILURE = '@detail/LOAD_USER_DETAIL_FAILURE'
}
/**
 * Data types
 */
export interface UserDetailData {
	'id': string;
	'name': string;
	'avatar': string;

	'headList': any;
	'cover_img': string;
	'img_list': any;
	'gender': string;
	'birthday': number;
	'living_place': any;
	'hometown': any;
	'education': string;
	'school': string;
	'job': string;
	"operate_id": string;
	'introduce': any;
	'friends_impression': any;
	'questions_answers': any;
	'age': number;
	'height': number;
	'city': string;
	'university': string;
	'ideal_object': any;
	'is_show': boolean;
	'sort': number;
	'is_removed': boolean;
	'create_date': number;
	'update_date': number;
	'private_info': any;
	'is_buy': boolean;
	'type': string;
	'recommend': any;
}
/**
 * State types
 */

export interface UserDetailDataState {
	readonly data: UserDetailData;
	readonly loading: boolean;
	readonly error: boolean;
}

/**
 * Action types
 */
export enum FeeTypeTypes {
	LOAD_GET_FEETYPE_REQUEST = '@detail/LOAD_GET_FEETYPE_REQUEST',
	LOAD_GET_FEETYPE_SUCCESS = '@detail/LOAD_GET_FEETYPE_SUCCESS',
	LOAD_GET_FEETYPE_FAILURE = '@detail/LOAD_GET_FEETYPE_FAILURE'
}
/**
 * Data types
 */
export interface FeeTypeList {
	five: {price: number, count: number, discount: number, reduce: number};
	one: {price: number, count: number, discount: number, reduce: number};
	ten: {price: number, count: number, discount: number, reduce: number};
	[key:string]: any;
}
/**
 * State types
 */
export interface FeeTypeState {
	readonly data: FeeTypeList;
	readonly loading: boolean;
	readonly error: boolean;
}

/**
 * Action types
 */
export enum RedFeeTypeTypes {
	LOAD_GET_RED_FEETYPE_REQUEST = '@detail/LOAD_GET_RED_FEETYPE_REQUEST',
	LOAD_GET_RED_FEETYPE_SUCCESS = '@detail/LOAD_GET_RED_FEETYPE_SUCCESS',
	LOAD_GET_RED_FEETYPE_FAILURE = '@detail/LOAD_GET_RED_FEETYPE_FAILURE'
}
/**
 * Data types
 */
export interface RedFeeTypeList {
	'red_one': {price: number, count: number};
	'red_three': {price: number, count: number};
	[key:string]: any;
}
/**
 * Action types
 */
export interface RedFeeTypeState {
	readonly data: RedFeeTypeList;
	readonly loading: boolean;
	readonly error: boolean;
}

/**
 * Action types
 */
export enum RedFreeConfigTypes {
	LOAD_GET_RFEE_REECONFIG_REQUEST = '@detail/LOAD_GET_RFEE_REECONFIG_REQUEST',
	LOAD_GET_RFEE_REECONFIG_SUCCESS = '@detail/LOAD_GET_RFEE_REECONFIG_SUCCESS',
	LOAD_GET_RFEE_REECONFIG_FAILURE = '@detail/LOAD_GET_RFEE_REECONFIG_FAILURE'
}
/**
 * Data types
 */
export interface RedFreeConfigList {
	'modal_qrcode': string;
	'detail_qrcode': string;
}
/**
 * Action types
 */
export interface RedFreeConfigState {
	readonly data: RedFreeConfigList;
	readonly loading: boolean;
	readonly error: boolean;
}
